import React, { useContext, useEffect } from 'react';
import './JoinForm.scss';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MyContext from '../../../Uttis/MyContext';
import axios from 'axios';

const JoinForm = () => {
  const { join,setStore,store,setJoin,url,setLoading } = useContext(MyContext);
  const totalvalue = 3000

  useEffect(() => {
    // Store data in sessionStorage whenever store changes
    sessionStorage.setItem('formData', JSON.stringify(store));
  }, [store]);
 

  return (
    <Modal open={join}  onClose={() => setJoin(false)} center>
      
      <Formik
        initialValues={{ name: '', email: '', mobile: '', terms: false }}
        validationSchema={Yup.object({
            name: Yup.string()
              .required('Name is required')
              .min(2, 'Name must be at least 2 characters long'),
            email: Yup.string()
              .email('Invalid email format')
              .required('Email is required'),
            mobile: Yup.string()
              .required('Mobile number is required')
              .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
            terms: Yup.boolean()
              .oneOf([true], 'You must accept the terms and conditions'),
          })}
        onSubmit={async(values) => {

        
          
                try {
                  setLoading(true)
                  const { data } = await axios.post(
                    `${url}/create-payment`,
                    {
                      amount: totalvalue,
                      orderId: `order_${new Date().getTime()}`,
                      email:values.email
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                      }
                    }
                  );
            
                  if (data.success) {
                    setStore({ email: values.email, mobile: values.mobile, name: values.name });
                    
                 
                    setJoin(false)
                    window.location.href = data.data; 

                  } else {
                    alert('Failed to initiate payment. Please try again.');
                  }
                } catch (error) {
                  console.error('Payment Error:', error.response?.data?.error || error.message);
                  alert('An error occurred during payment. Please try again.');
                }finally{
                    setLoading(false)
                }
             
        }}
      >
        {({ isSubmitting }) => (
          <Form className="join-form">
        
          <h2 >Join Now</h2>
       
         
        

         
            <div className="form-group">
           
              <label htmlFor="name">Name</label>
              <Field type="text" id="name" name="name"  placeholder="Enter Name"/>
              <ErrorMessage name="name"  autoComplete="off" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field type="email" autoComplete="off" id="email" name="email" placeholder="abc@gmail.com"/>
              <ErrorMessage name="email" component="div" className="error" />
            </div>

          

          

            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <Field type="text"  autoComplete="off" id="mobile" name="mobile" placeholder="mobile no."/>
              <ErrorMessage name="mobile" component="div" className="error" />
            </div>

           
            <button type="submit" disabled={isSubmitting} >
           Complete Registration
          </button>
         

            <div className="form-group">
         <div className='part'>
              <Field type="checkbox" id="terms" name="terms" />
              <label htmlFor="terms" className="terms-label">
                I accept the terms and conditions
              </label>
              </div>
           
              <ErrorMessage name="terms" component="div" className="error" />
            </div>

          

           
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default JoinForm;
