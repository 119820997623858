import React, { useContext } from 'react';
import './Domain.scss';
import Data from '../../../WebBatch/Json';
import MyContext from '../../../../Uttis/MyContext';

const Domain = () => {

  const{select} =useContext(MyContext)
  return (
    <div className='domainok'>
      {
        Data
        .map((item) => (
          <div key={item.id} className='card'>
        
          
            <h3 className="card-title">{select==='internship'?item.batch:item.cbatch}</h3>
           
            <button onClick={()=>window.open(`/it/${select==='internship'?item.param:item.cparam}`)}>Know More</button>
            </div>
           
         
        ))
      }
    </div>
  );
};

export default Domain;
