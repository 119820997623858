import React, { useContext, useEffect, useState } from 'react'
import './Info.scss'
import { GoVerified } from "react-icons/go";
import MyContext from '../../../Uttis/MyContext';
import { MdOutlineChromeReaderMode } from 'react-icons/md';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { GiMoneyStack } from 'react-icons/gi';
import { IoMdTime } from "react-icons/io";
import { TbReportMoney } from "react-icons/tb";
import Module from '../Module/Module';
import Collage from '../../Home/Collage-slider/Collage';
import Review from '../../Home/Reviewslider/Review';
import Certificate from '../../Header/Students/Certification/Certificate';
import Accordian from '../Accordian/Accordian';
import Steps from '../Steps/Steps';

const Info = ({outer,batch}) => {


  const[am,setAm] = useState('-100px')

  const handles = () =>{
    if(window.scrollY>1000){
      setAm('0px')
    }else{
      setAm('-100px')
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll',handles)
  },[])

  const{setJoin} =useContext(MyContext)
  return (
    <div className='webu-info'>
  <div className='part1'>

   <div className='regtu' style={{bottom:am}}>
   <button onClick={()=>setJoin(true)}>{batch.includes('internship')?'Register here':'Register To Enroll'}</button>
   </div>
    <h2 className='infoa'>{batch.includes('internship')?'Internship':'Course'}  Information</h2>

    <div className='p-type'>
    <div className='first'>
    <MdOutlineChromeReaderMode />
    <div className='mode'>
    <p>mode:</p>
    <h4>online/offline</h4>
    </div>
    </div>

  
    <div className='first'>
    <IoDocumentTextOutline />
    <div className='mode'>
    <p>documents:</p>
    <h4>aadhar card,photo</h4>
    </div>
    </div>

    <div className='first'>
    <GiMoneyStack/>
    <div className='mode'>
    <p>payment Method:</p>
    <h4>online/cheque</h4>
    </div>
    </div>


    {batch.includes('training') &&

      <>

    <div className='first'>
    <IoMdTime/>
    <div className='mode'>
    <p>Duration:</p>
    <h4>{outer.duration}</h4>
    </div>
    </div>

    <div className='first'>
    <TbReportMoney />
    <div className='mode'>
    <p>Fees Structure:</p>
    <h4>&#8377;{outer.fees}</h4>
    </div>
    </div>

    
    </>

    }


    </div>

    

    <Module outer={outer} batch={batch}/>

    
  <div className='oneb'/>
  { batch.includes('internship') && <Collage main={true}/>}

 
   
    <Review show={true} batch={batch}/>

     
    <div className='oneb'/>

    <Certificate show={true}/>

   <Steps batch={batch}/>

     <Accordian batch={batch}/>

  
    </div>

    
    
{batch.includes('internship')?
  <div className='part2'>
    <li><span>What You Will Get <GoVerified /></span>
    <p>FROM BEGINEER TO ADVANCE</p>
    </li>
    <li>📈 Comprehensive Internship Report Guidance</li>
    <li>🚀 Hands-On Projects & Skill Development</li>
    <li>🎓 Certification of Completion & Recommendation Letters</li>
    <li>💼 Flexible Work Environment to Balance Academics</li>
    <li>🌐 Exposure to the Latest Tools & Technologies</li>
    <li>🌱 Opportunity for Full-Time Roles Post Internship</li>

    <button onClick={()=>setJoin(true)}>Register to join</button>
</div>:
<div className='part2'>
    <li><span>What You Will Gain <GoVerified /></span>
        <p>FROM BEGINNER TO ADVANCED</p>
    </li>
    <li>📈 In-Depth Training Materials & Resources</li>
    <li>🚀 Hands-On Projects & Practical Skill Development</li>
    <li>🎓 Certification of Completion & Achievement Badges</li>
    <li>💼 Flexible Schedule to Suit Academic Commitments</li>
    <li>🌐 Access to Industry-Standard Tools & Technologies</li>
    <li>🌱 Pathway to Advanced Courses</li>

    <button onClick={() => setJoin(true)}>Register to Enroll</button>
</div>
}
  



    </div>
  )
}

export default Info