import React from 'react';
import './Accordian.scss';
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


const Accordian = ({batch}) => {

    const faqList = [
        { id: 1, ques: "What are the prerequisites for joining this internship?", ans: "Basic knowledge of the field you're interested in is recommended but not mandatory." },
        { id: 2, ques: "Is this internship paid?", ans: "Yes, our internship program offers a competitive stipend." },
        { id: 3, ques: "How long does the internship last?", ans: "Our internship duration typically ranges from 8 to 12 weeks." },
        { id: 4, ques: "Will I receive a certificate upon completion?", ans: "Yes, a certificate of completion is provided at the end of the internship." },
        { id: 5, ques: "Can this internship be done remotely?", ans: "We offer both in-office and remote internship options." },
        { id: 6, ques: "What skills will I gain from this internship?", ans: "You'll gain hands-on experience, industry knowledge, and various technical and soft skills." },
        { id: 7, ques: "Is there a chance for a full-time position post-internship?", ans: "Yes, high-performing interns may be considered for full-time roles." },
        { id: 8, ques: "How do I apply for the internship?", ans: "You can apply by clicking on register button or contacting our HR department." },
        { id: 9, ques: "Are there mentorship and training programs?", ans: "Yes, each intern is paired with a mentor and provided with necessary training resources." },
        { id: 10, ques: "What is the selection process for the internship?", ans: "Our selection process includes an application review followed by an interview." },
    ];

    const courseFaqList = [
        { id: 1, ques: "What are the prerequisites for enrolling in this course?", ans: "Basic knowledge of related subjects may be helpful but is not mandatory." },
        { id: 2, ques: "Is this course suitable for beginners?", ans: "Yes, this course is designed to accommodate both beginners and those with some experience." },
        { id: 3, ques: "What format does this course follow?", ans: "This course includes lectures, hands-on projects, and interactive Q&A sessions." },
        { id: 4, ques: "Will I receive a certification upon completion?", ans: "Yes, a certificate is awarded after successful course completion." },
        { id: 5, ques: "Can I take this course online?", ans: "Yes, this course is available in both online and in-person formats." },
        { id: 6, ques: "What topics will I learn in this course?", ans: "You'll cover essential topics and much more." },
        { id: 7, ques: "Are there any exams or assessments?", ans: "Yes, there are periodic assessments and a final exam to test your knowledge." },
        { id: 8, ques: "How do I apply for this course?", ans: "You can apply by clicking on the register to enroll button or contacting our admissions team." },
        { id: 9, ques: "Is financial aid or a payment plan available?", ans: "Yes, we offer payment plans and financial aid options for eligible students." },
        { id: 10, ques: "Will I have access to course materials after completion?", ans: "Yes, students will have continued access to course materials for future reference." }
    ];

    const filterdata= batch.includes('internship')?faqList:courseFaqList
    
    return (
        <div className='who'>
            <h2>Frequently Asked Questions...</h2>
            <div className='free'>
                <Accordion allowZeroExpanded style={{border:'none'}}>
                    {filterdata.map((Item) => (
                        <AccordionItem key={Item.id}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {Item.ques}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {Item.ans}
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default Accordian;
