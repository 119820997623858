

const PhpJson = 
  
  [
  
  
    {
        "id": 1,
        "html_title": "intro",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Introduction to PHP",
            "bootstrap_title_desc": "PHP (Hypertext Preprocessor) is a popular open-source, server-side scripting language used primarily for web development. PHP enables developers to create dynamic content that interacts with databases, making it a critical component of many modern web applications. With PHP, you can build anything from simple web pages to complex applications, including content management systems (CMS) and e-commerce platforms."
          },
          {
            "bootstrap_title_heading": "Prerequisites",
            "bootstrap_title_desc": "Before diving into PHP, ensure you have the following software installed:\n\n- **PHP**: PHP is required to run PHP scripts on your machine. You can download the latest version from [php.net](https://www.php.net/downloads).\n\n- **Composer**: Composer is a dependency manager for PHP, simplifying the installation of libraries and packages. Download it from [getcomposer.org](https://getcomposer.org/download/)."
          },
          {
            "bootstrap_title_heading": "Setting Up Your PHP Environment",
            "bootstrap_title_desc": "To start building with PHP, set up your development environment as follows:\n\n1. **Install XAMPP**: XAMPP is a development environment that includes Apache, MySQL, PHP, and Perl, allowing you to run PHP scripts on a local server. Download it from [Apache Friends](https://www.apachefriends.org/index.html) and follow the installation instructions for your operating system.\n\n2. **Set Up a New PHP Project**:\n   - After installing XAMPP, navigate to the `htdocs` folder within the XAMPP directory (e.g., `C:\\xampp\\htdocs` on Windows).\n   - Create a new folder for your project, such as `my-php-app`, and add an `index.php` file to start coding.\n\n3. **Run Your Project**:\n   - Open XAMPP and start the Apache server.\n   - In your browser, visit `http://localhost/my-php-app` to see your PHP application in action."
          }
        ],
        "coursename": "Intro (day-1)"
      },

      {
        "id": 2,
        "html_title": "syntax_variables_data_types",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Basic PHP Syntax",
            "bootstrap_title_desc": "PHP code is usually embedded within HTML and starts with `<?php` and ends with `?>`. Each PHP statement should end with a semicolon `;`.\n\nExample:\n\n```php\n<?php\n  echo 'Hello, World!';\n?>\n\n```\nThis will output: `Hello, World!`"
          },
          {
            "bootstrap_title_heading": "Variables in PHP",
            "bootstrap_title_desc": "Variables in PHP start with a `$` symbol followed by the variable name. Variable names must begin with a letter or underscore, and they are case-sensitive.\n\nExample:\n\n```php\n<?php\n  $name = 'John';\n  $age = 25;\n  echo $name;\n?>\n\n```\nThis will output: `John`."
          },
          {
            "bootstrap_title_heading": "Data Types in PHP",
            "bootstrap_title_desc": "PHP supports several data types:\n\n- **String**: A sequence of characters, e.g., `$name = 'Hello';`\n- **Integer**: A non-decimal number, e.g., `$age = 25;`\n- **Float**: A decimal number, e.g., `$price = 19.99;`\n- **Boolean**: Represents `true` or `false`, e.g., `$isStudent = true;`\n- **Array**: A collection of values, e.g., `$colors = array('red', 'blue', 'green');`\n- **Object**: An instance of a class that can store data and functions.\n- **NULL**: A variable with no value, e.g., `$value = null;`\n\nExample:\n\n```php\n<?php\n  $string = 'Hello, World!';\n  $integer = 100;\n  $float = 10.5;\n  $boolean = true;\n  $array = array(1, 2, 3);\n?>\n\n```\nThis code defines different types of variables in PHP."
          }
        ],
        "coursename": "PHP Basics (day-2)"
      },


      {
        "id": 3,
        "html_title": "strings_numbers_casting",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Working with Strings",
            "bootstrap_title_desc": "Strings in PHP are sequences of characters. You can use single or double quotes to define a string. PHP provides various functions to manipulate strings, such as `strlen()` to find the string length and `str_replace()` to replace parts of a string.\n\nExample:\n\n```php\n<?php\n  $greeting = 'Hello';\n  $name = 'Alice';\n  $message = \"$greeting, $name!\";  // Double quotes allow variable interpolation\n\n  echo $message; // Outputs: Hello, Alice!\n  echo '<br>Message length: ' . strlen($message); // Outputs length\n?>\n```\nThis will output:\n`Hello, Alice!` and the message length."
          },
          {
            "bootstrap_title_heading": "Working with Numbers",
            "bootstrap_title_desc": "PHP supports integers and floats, and provides built-in functions for mathematical operations. You can use `+`, `-`, `*`, `/`, and `%` operators for basic calculations.\n\nExample:\n\n```php\n<?php\n  $num1 = 15;\n  $num2 = 4;\n\n  $sum = $num1 + $num2;\n  $difference = $num1 - $num2;\n  $product = $num1 * $num2;\n  $quotient = $num1 / $num2;\n  $remainder = $num1 % $num2;\n\n  echo 'Sum: ' . $sum . '<br>Difference: ' . $difference . '<br>Product: ' . $product . '<br>Quotient: ' . $quotient . '<br>Remainder: ' . $remainder;\n?>\n```\n\nThis example performs basic arithmetic operations."
          },
          {
            "bootstrap_title_heading": "Casting in PHP",
            "bootstrap_title_desc": "Casting is converting one data type to another. In PHP, you can cast variables by specifying the data type in parentheses before the variable. PHP supports casting to integer, float, string, array, and object.\n\nExample:\n\n```php\n<?php\n  $str_number = '100';\n  $converted_number = (int) $str_number; // Casting string to integer\n\n  $float_value = 45.67;\n  $integer_value = (int) $float_value; // Casting float to integer\n\n  echo 'String to Integer: ' . $converted_number . '<br>Float to Integer: ' . $integer_value;\n?>\n```\n\nThis will output:\n`String to Integer: 100` and `Float to Integer: 45`."
          }
        ],
        "coursename": "Strings (day-3)"
      },

      {
        "id": 4,
        "html_title": "math_constants_operators",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Math Functions in PHP",
            "bootstrap_title_desc": "PHP offers many built-in math functions to perform calculations. Some common ones include:\n\n- **`abs()`**: Returns the absolute value\n- **`pow()`**: Calculates the power of a number\n- **`sqrt()`**: Returns the square root\n- **`round()`**: Rounds a floating-point number\n\nExample:\n\n```php\n<?php\n  $num = -15;\n  $power = pow(3, 4);  // 3 raised to the power of 4\n  $sqrt = sqrt(64);    // Square root of 64\n\n  echo 'Absolute: ' . abs($num) . '<br>Power: ' . $power . '<br>Square Root: ' . $sqrt;\n?>\n```\nThis example demonstrates some key PHP math functions."
          },
          {
            "bootstrap_title_heading": "Defining Constants",
            "bootstrap_title_desc": "Constants are like variables, but once defined, their values cannot be changed. Constants are defined using the `define()` function and are globally accessible.\n\nExample:\n\n```php\n<?php\n  define('SITE_NAME', 'My Website');\n  define('PI', 3.14159);\n\n  echo 'Site: ' . SITE_NAME . '<br>Pi: ' . PI;\n?>\n```\nIn this example, `SITE_NAME` and `PI` are constants that hold string and numerical values."
          },
          {
            "bootstrap_title_heading": "Operators in PHP",
            "bootstrap_title_desc": "Operators are symbols that tell the PHP processor to perform specific actions. PHP has various types of operators:\n\n- **Arithmetic Operators**: `+`, `-`, `*`, `/`, `%`\n- **Assignment Operators**: `=`, `+=`, `-=`, etc.\n- **Comparison Operators**: `==`, `!=`, `>`, `<`, `>=`, `<=`\n- **Logical Operators**: `&&`, `||`, `!`\n\nExample:\n\n```php\n<?php\n  $a = 10;\n  $b = 20;\n  $sum = $a + $b;\n  $is_equal = ($a == $b);\n  $is_greater = ($a > $b);\n\n  echo 'Sum: ' . $sum . '<br>Is Equal: ' . ($is_equal ? 'Yes' : 'No') . '<br>Is A Greater than B: ' . ($is_greater ? 'Yes' : 'No');\n?>\n```\nThis example demonstrates arithmetic, comparison, and logical operators."
          }
        ],
        "coursename": "Operators (day-4)"
      },

      {
        "id": 5,
        "html_title": "conditionals_loops_functions",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Conditional Statements (if...else)",
            "bootstrap_title_desc": "Conditional statements are used to perform different actions based on conditions. The `if...else` statement allows you to execute code based on whether a condition is true or false.\n\nExample:\n\n```php\n<?php\n  $age = 18;\n\n  if ($age >= 18) {\n    echo 'You are eligible to vote.';\n  } else {\n    echo 'You are not eligible to vote.';\n  }\n?>\n```\nThis example checks if a person is eligible to vote based on age."
          },
          {
            "bootstrap_title_heading": "Switch Statement",
            "bootstrap_title_desc": "The `switch` statement is an alternative to `if...else` when you want to compare the same variable against multiple values. Each case specifies a block of code to be executed if the case value matches the variable.\n\nExample:\n\n```php\n<?php\n  $day = 'Monday';\n\n  switch ($day) {\n    case 'Monday':\n      echo 'Start of the work week.';\n      break;\n    case 'Friday':\n      echo 'End of the work week!';\n      break;\n    default:\n      echo 'Have a nice day!';\n      break;\n  }\n?>\n```\nThis example outputs a message depending on the day of the week."
          },
          {
            "bootstrap_title_heading": "Loops",
            "bootstrap_title_desc": "Loops are used to repeat code multiple times. PHP supports several types of loops:\n\n- **`for` loop**: Ideal for iterating a set number of times\n- **`while` loop**: Continues as long as a condition is true\n- **`do...while` loop**: Executes code at least once before checking the condition\n\nExample:\n\n```php\n<?php\n  for ($i = 1; $i <= 5; $i++) {\n    echo 'Number: ' . $i . '<br>';\n  }\n\n  $count = 1;\n  while ($count <= 3) {\n    echo 'Count: ' . $count . '<br>';\n    $count++;\n  }\n\n  $num = 1;\n  do {\n    echo 'Num: ' . $num . '<br>';\n    $num++;\n  } while ($num <= 2);\n?>\n```\nThis example demonstrates the `for`, `while`, and `do...while` loops."
          },
          {
            "bootstrap_title_heading": "Functions",
            "bootstrap_title_desc": "Functions are reusable blocks of code that can be called with specific parameters to perform a task. Define a function with the `function` keyword, and then call it by its name.\n\nExample:\n\n```php\n<?php\n  function greet($name) {\n    return 'Hello, ' . $name . '!';\n  }\n\n  echo greet('Alice'); // Outputs: Hello, Alice!\n?>\n```\nThis example defines a `greet` function that takes a name as an argument and returns a greeting."
          }
        ],
        "coursename": "Loops (day-5)"
      },

      {
        "id": 6,
        "html_title": "arrays_superglobals_regex",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Arrays in PHP",
            "bootstrap_title_desc": "Arrays in PHP are used to store multiple values in a single variable. PHP supports both indexed and associative arrays.\n\nExample:\n\n```php\n<?php\n  // Indexed Array\n  $colors = array('Red', 'Green', 'Blue');\n  echo 'First color: ' . $colors[0] . '<br>';\n\n  // Associative Array\n  $person = array('name' => 'Alice', 'age' => 25);\n  echo 'Name: ' . $person['name'] . '<br>Age: ' . $person['age'];\n?>\n```\nIn this example, we create an indexed array of colors and an associative array for a person."
          },
          {
            "bootstrap_title_heading": "Superglobals in PHP",
            "bootstrap_title_desc": "Superglobals are built-in variables in PHP that are always accessible, regardless of scope. Common superglobals include:\n\n- **`$_GET`**: An associative array of variables passed to the current script via URL parameters.\n- **`$_POST`**: An associative array of variables passed to the current script via HTTP POST method.\n- **`$_SESSION`**: An associative array used to store session variables.\n\nExample:\n\n```php\n<?php\n  // Using $_GET\n  if (isset($_GET['name'])) {\n    echo 'Hello, ' . $_GET['name'];\n  }\n\n  // Using $_POST\n  if ($_SERVER['REQUEST_METHOD'] == 'POST') {\n    echo 'Form submitted!';\n  }\n?>\n```\nIn this example, we check for a name parameter from the URL and handle form submission with the POST method."
          },
          {
            "bootstrap_title_heading": "Regular Expressions (Regex)",
            "bootstrap_title_desc": "Regular expressions are a powerful way to perform pattern matching and text manipulation. PHP supports regex through the PCRE (Perl Compatible Regular Expressions) library.\n\nExample:\n\n```php\n<?php\n  $pattern = '/^Hello/';\n  $string = 'Hello, world!';\n\n  if (preg_match($pattern, $string)) {\n    echo 'Pattern matched!';\n  } else {\n    echo 'No match found.';\n  }\n?>\n```\nIn this example, we check if the string starts with 'Hello' using `preg_match()`."
          }
        ],
        "coursename": "Arrays (day-6)"
      },


      {
        "id": 7,
        "html_title": "form_handling_validation",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": `Form Handling in PHP`,
            "bootstrap_title_desc": `PHP allows you to handle form data sent via POST or GET methods. Using the superglobal arrays $_POST and $_GET, you can access form input values.
      
Example:

\`\`\`php
<!DOCTYPE html>
<html>
<body>

<form method="POST" action="">
Name: <input type="text" name="name">
<input type="submit" value="Submit">
</form>

<?php
if ($_SERVER['REQUEST_METHOD'] == 'POST') {
$name = $_POST['name'];
echo 'Hello, ' . htmlspecialchars($name) . '!';
}
?>

</body>
</html>
\`\`\`

In this example, the form uses the POST method, and PHP retrieves the input value using $_POST. htmlspecialchars() is used to prevent cross-site scripting (XSS).`
},
{
"bootstrap_title_heading": `Form Validation in PHP`,
"bootstrap_title_desc": `Form validation ensures the correctness of user input before processing. PHP allows both server-side validation (using PHP code) and client-side validation (using JavaScript, though server-side is more secure).

Example:

\`\`\`php
<!DOCTYPE html>
<html>
<body>

<?php
$nameErr = '';
$name = '';

if ($_SERVER['REQUEST_METHOD'] == 'POST') {
if (empty($_POST['name'])) {
$nameErr = 'Name is required';
} else {
$name = test_input($_POST['name']);
if (!preg_match('/^[a-zA-Z ]*$/', $name)) {
$nameErr = 'Only letters and white space allowed';
}
}
}

function test_input($data) {
$data = trim($data);
$data = stripslashes($data);
$data = htmlspecialchars($data);
return $data;
}
?>

<form method="POST" action="">
Name: <input type="text" name="name" value="<?php echo $name; ?>">
<span style="color:red;">* <?php echo $nameErr; ?></span>
<br><br>
<input type="submit" value="Submit">
</form>

<?php
if (!empty($name) && empty($nameErr)) {
echo 'Hello, ' . $name . '!';
}
?>

</body>
</html>
\`\`\`

This example checks if the 'Name' field is empty and restricts it to letters and spaces. It shows error messages if validation fails.`
}
],
      "coursename": "Form Validation (day-7)"
      },


      {
        "id": 8,
        "html_title": "date_time_methods",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": `Getting the Current Date and Time`,
            "bootstrap_title_desc": `PHP provides various functions to get and format the current date and time.
      
Example:

\`\`\`php
<?php
echo "Today's date is: " . date("Y-m-d") . "<br>";
echo "Current time is: " . date("H:i:s");
?>
\`\`\`

In this example:
- \`Y\` outputs the full year (e.g., 2023),
- \`m\` is the month (01-12),
- \`d\` is the day of the month (01-31),
- \`H\` gives the hour in 24-hour format (00-23),
- \`i\` gives the minutes, and
- \`s\` gives the seconds.`
},
{
"bootstrap_title_heading": `Formatting Dates and Times`,
"bootstrap_title_desc": `You can use the \`date\` function to format dates and times in various styles.

Example:

\`\`\`php
<?php
$formattedDate = date("l, F j, Y, g:i a");
echo "Formatted date and time: " . $formattedDate;
?>
\`\`\`

In this example, the date is formatted as:
- \`l\`: Day of the week (e.g., Monday),
- \`F\`: Full month name (e.g., January),
- \`j\`: Day of the month without leading zeros,
- \`Y\`: Four-digit year,
- \`g\`: 12-hour format of the hour,
- \`a\`: Lowercase am or pm.`
},
{
"bootstrap_title_heading": `Using Timestamps`,
"bootstrap_title_desc": `A timestamp is a sequence of characters representing the date and time. PHP uses the Unix timestamp (the number of seconds since January 1, 1970).

Example:

\`\`\`php
<?php
$timestamp = time();
echo "Current Unix timestamp: " . $timestamp . "<br>";
echo "Date from timestamp: " . date("Y-m-d H:i:s", $timestamp);
?>
\`\`\`

In this example, \`time()\` returns the current timestamp, and \`date\` formats it into a readable date.`
},
{
"bootstrap_title_heading": `Creating and Formatting Dates with mktime()`,
"bootstrap_title_desc": `\`mktime()\` is used to create a timestamp for a specific date and time.

Example:

\`\`\`php
<?php
$customDate = mktime(10, 30, 0, 12, 25, 2023);
echo "Custom date: " . date("Y-m-d H:i:s", $customDate);
?>
\`\`\`

In this example:
- The \`mktime\` arguments are hour, minute, second, month, day, year, creating a timestamp for December 25, 2023, at 10:30:00.`
},
{
"bootstrap_title_heading": `Date Differences with DateTime`,
"bootstrap_title_desc": `The \`DateTime\` class allows you to work with date differences and more advanced date functions.

Example:

\`\`\`php
<?php
$date1 = new DateTime("2023-01-01");
$date2 = new DateTime("2023-12-31");
$interval = $date1->diff($date2);
echo "Difference: " . $interval->days . " days";
?>
\`\`\`

In this example, \`diff\` calculates the difference between two dates, returning the number of days between them.`
}
],
"coursename": "Date Methods (day-8)"
},

{
    "id": 9,
    "html_title": "include_require",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": `Using include() in PHP`,
        "bootstrap_title_desc": `The \`include()\` function allows you to insert the content of one PHP file into another. It's useful for reusing code, like headers, footers, or navigation.
  
Example:

\`\`\`php
<?php
include("header.php");
echo "Welcome to the main content!";
?>
\`\`\`

In this example, \`header.php\` is included, so its content will display before the "Welcome to the main content!" message. If \`header.php\` is missing, a warning is shown, but the script continues to run.`
},
{
"bootstrap_title_heading": `Using require() in PHP`,
"bootstrap_title_desc": `\`require()\` works similarly to \`include()\`, but it will produce a fatal error if the specified file is not found, stopping the script.

Example:

\`\`\`php
<?php
require("header.php");
echo "This content depends on header.php!";
?>
\`\`\`

In this example, if \`header.php\` is missing, the script stops immediately with a fatal error. Use \`require\` when the included file is essential for the script's operation.`
},
{
"bootstrap_title_heading": `Using include_once() and require_once()`,
"bootstrap_title_desc": `\`include_once()\` and \`require_once()\` ensure the file is only included once, even if called multiple times in the script. This helps prevent redeclaring functions or variables.

Example:

\`\`\`php
<?php
include_once("header.php");
include_once("header.php"); // This will include header.php only once
?>
\`\`\`

\`require_once()\` works similarly but will halt the script if the file is missing.`
}
],
"coursename": "Include (day-9)"
  },



  {
    "id": 10,
    "html_title": "file_operations",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": `Opening and Reading a File`,
        "bootstrap_title_desc": `PHP provides functions to open and read files. Use \`fopen()\` to open a file, and \`fread()\` or \`fgets()\` to read the content.
  
Example:

\`\`\`php
<?php
$file = fopen("example.txt", "r");
if ($file) {
while (($line = fgets($file)) !== false) {
echo $line . "<br>";
}
fclose($file);
} else {
echo "Error opening file!";
}
?>
\`\`\`

In this example, \`fopen("example.txt", "r")\` opens the file in read mode, \`fgets()\` reads one line at a time, and \`fclose()\` closes the file after reading.`
},
{
"bootstrap_title_heading": `Creating and Writing to a File`,
"bootstrap_title_desc": `To create and write to a file, use \`fopen()\` with "w" or "a" modes, and \`fwrite()\` to write data.

Example:

\`\`\`php
<?php
$file = fopen("newfile.txt", "w");
if ($file) {
$content = "This is a new file with some content.";
fwrite($file, $content);
fclose($file);
echo "File written successfully!";
} else {
echo "Error creating file!";
}
?>
\`\`\`

In this example, \`fopen("newfile.txt", "w")\` creates a new file or overwrites if it exists. \`fwrite()\` writes the content to the file, and \`fclose()\` closes it after writing.`
},
{
"bootstrap_title_heading": `Appending to a File`,
"bootstrap_title_desc": `Use \`fopen()\` with "a" mode to append content to an existing file without overwriting.

Example:

\`\`\`php
<?php
$file = fopen("newfile.txt", "a");
if ($file) {
$content = "\\nThis is additional content.";
fwrite($file, $content);
fclose($file);
echo "Content appended successfully!";
} else {
echo "Error opening file!";
}
?>
\`\`\`

In this example, \`fopen("newfile.txt", "a")\` opens the file in append mode, so new content is added at the end without removing existing content.`
}
],
"coursename": "File Operations (day-10)"
},


{
    "id": 11,
    "html_title": "file_upload",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": `Uploading a File in PHP`,
        "bootstrap_title_desc": `PHP allows you to upload files using HTML forms. Use the \`$_FILES\` superglobal to handle file uploads.
  
  Example:
  
\`\`\`php
<!DOCTYPE html>
<html>
<body>

<form action="" method="POST" enctype="multipart/form-data">
Select file to upload:
<input type="file" name="uploadedFile">
<input type="submit" value="Upload">
</form>

<?php
if ($_SERVER['REQUEST_METHOD'] === 'POST') {
$targetDir = "uploads/";
$targetFile = $targetDir . basename($_FILES["uploadedFile"]["name"]);
$uploadOk = 1;
$fileType = strtolower(pathinfo($targetFile, PATHINFO_EXTENSION));

// Check if file is an actual image or fake
$check = getimagesize($_FILES["uploadedFile"]["tmp_name"]);
if ($check !== false) {
echo "File is an image - " . $check["mime"] . ".";
$uploadOk = 1;
} else {
echo "File is not an image.";
$uploadOk = 0;
}

// Check if file already exists
if (file_exists($targetFile)) {
echo "Sorry, file already exists.";
$uploadOk = 0;
}

// Allow certain file formats
if ($fileType != "jpg" && $fileType != "png" && $fileType != "jpeg" && $fileType != "gif" ) {
echo "Sorry, only JPG, JPEG, PNG & GIF files are allowed.";
$uploadOk = 0;
}

// Check if $uploadOk is set to 0 by an error
if ($uploadOk == 0) {
echo "Sorry, your file was not uploaded.";
} else {
if (move_uploaded_file($_FILES["uploadedFile"]["tmp_name"], $targetFile)) {
echo "The file ". htmlspecialchars(basename($_FILES["uploadedFile"]["name"])) . " has been uploaded.";
} else {
echo "Sorry, there was an error uploading your file.";
}
}
}
?>

</body>
</html>
\`\`\`

In this example:
- The HTML form uses \`enctype="multipart/form-data"\` to handle file uploads.
- \`move_uploaded_file()\` moves the uploaded file to the target directory.
- Basic validation checks the file type, size, and whether it’s an image.`
}
],
"coursename": "File Upload (day-11)"
},


{
    "id": 12,
    "html_title": "cookies_sessions",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": `Working with Cookies in PHP`,
        "bootstrap_title_desc": `Cookies are small pieces of data stored on the client side, used for tracking user preferences or login status. In PHP, you can set, retrieve, and delete cookies with the \`setcookie()\` function.
  
Example: Setting, Retrieving, and Deleting a Cookie

\`\`\`php
<!DOCTYPE html>
<html>
<body>

<?php
// Setting a cookie
setcookie("user", "John Doe", time() + (86400 * 30), "/"); // 86400 = 1 day

// Checking if the cookie is set
if (isset($_COOKIE["user"])) {
echo "Welcome, " . $_COOKIE["user"];
} else {
echo "User cookie is not set.";
}

// Deleting a cookie
setcookie("user", "", time() - 3600, "/");
?>

</body>
</html>
\`\`\`

In this example:
- \`setcookie()\` sets a cookie with the name \`user\`, which expires in 30 days.
- Checking if the cookie is set with \`isset()\`.
- Setting the cookie's expiration time to a past value effectively deletes it.`
},
{
"bootstrap_title_heading": "Working with Sessions in PHP",
"bootstrap_title_desc": `Sessions are a secure way to store data across different pages on the server side. PHP sessions store user data on the server, identified by a unique session ID.

Example: Starting, Setting, and Retrieving a Session

\`\`\`php
<!DOCTYPE html>
<html>
<body>

<?php
// Starting a session
session_start();

// Setting session variables
$_SESSION["username"] = "JaneDoe";
$_SESSION["email"] = "jane.doe@example.com";

// Accessing session variables
echo "Welcome, " . $_SESSION["username"];
echo "Your email: " . $_SESSION["email"];

// Removing session variables
unset($_SESSION["username"]);

// Destroying a session
session_destroy();
?>

</body>
</html>
\`\`\`

In this example:
- \`session_start()\` initializes a session or resumes an existing one.
- \`$_SESSION[]\` is used to set and retrieve session variables.
- \`unset()\` removes a specific session variable, and \`session_destroy()\` ends the session entirely.`
}
],
"coursename": "Cookies Sessions (day-12)"
},


{
  "id": 13,
  "html_title": "database-connection",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `connect.php`,
      "bootstrap_title_desc": `<?php
// database connection file
$servername = "localhost";
$username = "your_username";
$password = "your_password";
$dbname = "your_database_name";

//fill value
$servername = "localhost";  
$username = "root";         
$password = "";            
$dbname = "my_database";    

// Create connection
$conn = new mysqli($servername, $username, $password, $dbname);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully";
?>
`
},



{
  "bootstrap_title_heading": `create database`,
  "bootstrap_title_desc": `<?php
// Database server connection details
$servername = "localhost";
$username = "your_username";
$password = "your_password";

// Connect to the MySQL server
$conn = new mysqli($servername, $username, $password);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully<br>";

// Define the new database name
$dbname = "new_database_name";

// SQL query to create the database
$sql = "CREATE DATABASE $dbname";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "Database created successfully";
} else {
    echo "Error creating database: " . $conn->error;
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": `create table`,
  "bootstrap_title_desc": `<?php
// Database connection details
$servername = "localhost";
$username = "your_username";
$password = "your_password";
$dbname = "new_database_name"; // Change to your database name

// Connect to the MySQL server and the specified database
$conn = new mysqli($servername, $username, $password, $dbname);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully to the database<br>";

// SQL to create a new table
$sql = "CREATE TABLE users (
    id INT(11) AUTO_INCREMENT PRIMARY KEY,
    name VARCHAR(50) NOT NULL,
    email VARCHAR(50) NOT NULL UNIQUE,
    age INT(3),
    created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
)";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "Table 'users' created successfully";
} else {
    echo "Error creating table: " . $conn->error;
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": `insert data`,
  "bootstrap_title_desc": `<?php
// Database connection details
$servername = "localhost";
$username = "your_username";
$password = "your_password";
$dbname = "new_database_name"; // Replace with your actual database name

// Connect to the MySQL server and the specified database
$conn = new mysqli($servername, $username, $password, $dbname);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully to the database<br>";

// Define the data to be inserted
$name = "Jane Doe";
$email = "janedoe@example.com";
$age = 30;

// SQL query to insert data into the users table
$sql = "INSERT INTO users (name, email, age) VALUES ('$name', '$email', $age)";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "New record created successfully";
} else {
    echo "Error: " . $sql . "<br>" . $conn->error;
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": `find last id`,
  "bootstrap_title_desc": `<?php
// Database connection details
$servername = "localhost";
$username = "your_username";
$password = "your_password";
$dbname = "new_database_name"; // Replace with your actual database name

// Connect to the MySQL server and the specified database
$conn = new mysqli($servername, $username, $password, $dbname);

// Check connection
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
echo "Connected successfully to the database<br>";

// Define the data to be inserted
$name = "Jane Doe";
$email = "janedoe@example.com";
$age = 30;

// SQL query to insert data into the users table
$sql = "INSERT INTO users (name, email, age) VALUES ('$name', '$email', $age)";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "New record created successfully<br>";
    
    // Get the last inserted ID
    $last_id = $conn->insert_id; // Fetch the last inserted ID
    echo "The last inserted ID is: " . $last_id;
} else {
    echo "Error: " . $sql . "<br>" . $conn->error;
}

// Close the connection
$conn->close();
?>
`
},



],
"coursename": "Database Connection (day-13)"
},


{
  "id": 14,
  "html_title": "statements",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `connect.php`,
      "bootstrap_title_desc": `<?php
// Database credentials
$host = 'localhost';
$dbname = 'your_database_name';
$username = 'your_username';
$password = 'your_password';

// Create a new mysqli connection
$conn = new mysqli($host, $username, $password, $dbname);

// Check for connection errors
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}

// Prepare an SQL statement
$stmt = $conn->prepare("INSERT INTO users (name, email) VALUES (?, ?)");

// Check if preparation was successful
if ($stmt === false) {
    die("Error preparing statement: " . $conn->error);
}

// Bind parameters to the SQL statement (s for string data type)
$stmt->bind_param("ss", $name, $email);

// Set values for the bound parameters
$name = 'John Doe';
$email = 'johndoe@example.com';

// Execute the prepared statement
if ($stmt->execute()) {
    echo "New record created successfully";
} else {
    echo "Error: " . $stmt->error;
}

// Close the statement and connection
$stmt->close();
$conn->close();
?>
`
},


{
  "bootstrap_title_heading": `select data`,
  "bootstrap_title_desc": `<?php
// Database credentials
$host = 'localhost';
$dbname = 'your_database_name';
$username = 'your_username';
$password = 'your_password';

// Create a new mysqli connection
$conn = new mysqli($host, $username, $password, $dbname);

// Check for connection errors
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}

// Define the SQL query
$sql = "SELECT id, name, email FROM users";

// Execute the query
$result = $conn->query($sql);

// Check if there are results
if ($result->num_rows > 0) {
    echo "<table border='1'>";
    echo "<tr><th>ID</th><th>Name</th><th>Email</th></tr>";

    // Fetch and display each row
    while ($row = $result->fetch_assoc()) {
        echo "<tr>";
        echo "<td>" . htmlspecialchars($row['id']) . "</td>";
        echo "<td>" . htmlspecialchars($row['name']) . "</td>";
        echo "<td>" . htmlspecialchars($row['email']) . "</td>";
        echo "</tr>";
    }
    echo "</table>";
} else {
    echo "No records found.";
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": "Select Data with WHERE Clause",
  "bootstrap_title_desc": `<?php
// Database credentials
$host = 'localhost';
$dbname = 'your_database_name';
$username = 'your_username';
$password = 'your_password';

// Create a new mysqli connection
$conn = new mysqli($host, $username, $password, $dbname);

// Check for connection errors
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}

// Define the SQL query with a WHERE clause
$userId = 1; // Example: fetch user with id = 1
$sql = "SELECT id, name, email FROM users WHERE id = $userId";

// Execute the query
$result = $conn->query($sql);

// Check if there are results
if ($result->num_rows > 0) {
    echo "<table border='1'>";
    echo "<tr><th>ID</th><th>Name</th><th>Email</th></tr>";

    // Fetch and display each row
    while ($row = $result->fetch_assoc()) {
        echo "<tr>";
        echo "<td>" . htmlspecialchars($row['id']) . "</td>";
        echo "<td>" . htmlspecialchars($row['name']) . "</td>";
        echo "<td>" . htmlspecialchars($row['email']) . "</td>";
        echo "</tr>";
    }
    echo "</table>";
} else {
    echo "No records found.";
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": "Delete Data from Database",
  "bootstrap_title_desc": `<?php
// Database credentials
$host = 'localhost';
$dbname = 'your_database_name';
$username = 'your_username';
$password = 'your_password';

// Create a new mysqli connection
$conn = new mysqli($host, $username, $password, $dbname);

// Check for connection errors
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}

// Define the user ID to delete
$userId = 1; // Example: delete user with id = 1

// Define the DELETE SQL query
$sql = "DELETE FROM users WHERE id = $userId";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "Record deleted successfully";
} else {
    echo "Error deleting record: " . $conn->error;
}

// Close the connection
$conn->close();
?>
`
},

{
  "bootstrap_title_heading": "Update Data in Database",
  "bootstrap_title_desc": `<?php
// Database credentials
$host = 'localhost';
$dbname = 'your_database_name';
$username = 'your_username';
$password = 'your_password';

// Create a new mysqli connection
$conn = new mysqli($host, $username, $password, $dbname);

// Check for connection errors
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}

// Define the user ID and new data to update
$userId = 1; // Example: update user with id = 1
$newName = 'Jane Doe';
$newEmail = 'janedoe@example.com';

// Define the UPDATE SQL query
$sql = "UPDATE users SET name = '$newName', email = '$newEmail' WHERE id = $userId";

// Execute the query
if ($conn->query($sql) === TRUE) {
    echo "Record updated successfully";
} else {
    echo "Error updating record: " . $conn->error;
}

// Close the connection
$conn->close();
?>
`
}






],
"coursename": "Statement (day-14)"
},


{
  "id": 14,
  "html_title": "php-api-fetch",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `Using file_get_contents()`,
      "bootstrap_title_desc": `<?php
$apiUrl = "https://api.example.com/data"; // Replace with your API URL
$response = file_get_contents($apiUrl);
$data = json_decode($response, true); // Decode JSON to an associative array

if ($data) {
    // Process your data
    print_r($data);
} else {
    echo "Error fetching data";
}
?>
`
},


{
  "bootstrap_title_heading": ` Using cURL`,
  "bootstrap_title_desc": `<?php
$apiUrl = "https://api.example.com/data"; // Replace with your API URL
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, $apiUrl);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true); // Return the response instead of printing it
curl_setopt($ch, CURLOPT_HTTPGET, true); // Optional for GET request

$response = curl_exec($ch);
if ($response === false) {
    echo "Error: " . curl_error($ch);
} else {
    $data = json_decode($response, true); // Decode JSON to an associative array
    print_r($data);
}

curl_close($ch);
?>
`
},



{
  "bootstrap_title_heading": `create table using api`,
  "bootstrap_title_desc": `<?php
// Define the API URL
$apiUrl = "https://jsonplaceholder.typicode.com/posts";

// Fetch data from the API
$response = file_get_contents($apiUrl);
$data = json_decode($response, true); // Decode JSON to an associative array

// Check if data is successfully fetched
if ($data) {
    echo "<table border='1' cellpadding='10'>"; // Start the table with border and padding

    // Define table headers
    echo "<tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Title</th>
            <th>Body</th>
          </tr>";

    // Loop through each row of data and create table rows
    foreach ($data as $row) {
        echo "<tr>
                <td>" . htmlspecialchars($row['id']) . "</td>
                <td>" . htmlspecialchars($row['userId']) . "</td>
                <td>" . htmlspecialchars($row['title']) . "</td>
                <td>" . htmlspecialchars($row['body']) . "</td>
              </tr>";
    }

    echo "</table>"; // End the table
} else {
    echo "Error fetching data.";
}
?>
`
},









],
"coursename": "PHP API (day-15)"
},


{
  "id": 15,
  "html_title": "php-smtp-mail",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `Install PHPMailer`,
      "bootstrap_title_desc": `composer require phpmailer/phpmailer`
},

{
  "bootstrap_title_heading": `Mail.php`,
  "bootstrap_title_desc": `<?php
// Load PHPMailer classes
use PHPMailer\PHPMailer\PHPMailer;
use PHPMailer\PHPMailer\Exception;

// Include PHPMailer files (if not using Composer, specify the correct path)
require 'vendor/autoload.php';

$mail = new PHPMailer(true);

try {
    // SMTP server configuration
    $mail->isSMTP();
    $mail->Host = 'smtp.example.com'; // Replace with your SMTP server, e.g., smtp.gmail.com
    $mail->SMTPAuth = true;
    $mail->Username = 'your_email@example.com'; // Replace with your email
    $mail->Password = 'your_password'; // Replace with your email password
    $mail->SMTPSecure = PHPMailer::ENCRYPTION_STARTTLS; // Or PHPMailer::ENCRYPTION_SMTPS for SSL
    $mail->Port = 587; // Use 587 for TLS or 465 for SSL

    // Email settings
    $mail->setFrom('your_email@example.com', 'Your Name'); // Sender's email and name
    $mail->addAddress('recipient@example.com', 'Recipient Name'); // Add a recipient
    $mail->Subject = 'Test Email using PHPMailer';
    $mail->isHTML(true); // Set email format to HTML
    $mail->Body = '<h1>Hello</h1><p>This is a test email using PHPMailer with HTML formatting.</p>';
    $mail->AltBody = 'This is a test email using PHPMailer without HTML formatting.';

    // Send the email
    $mail->send();
    echo 'Email has been sent successfully';
} catch (Exception $e) {
    echo "Message could not be sent. Mailer Error: {$mail->ErrorInfo}";
}
?>
`
},


{
  "bootstrap_title_heading": `Form.php`,
  "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Contact Form</title>
</head>
<body>
    <form action="send_email.php" method="post">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name" required><br><br>
        
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" required><br><br>
        
        <button type="submit">Send Email</button>
    </form>

    <?php
use PHPMailer\PHPMailer\PHPMailer;
use PHPMailer\PHPMailer\Exception;

require 'vendor/autoload.php';

if ($_SERVER["REQUEST_METHOD"] == "POST") {
    // Retrieve form input
    $name = $_POST['name'];
    $email = $_POST['email'];
    
    $mail = new PHPMailer(true);

    try {
        // SMTP server configuration
        $mail->isSMTP();
        $mail->Host = 'smtp.example.com'; // Replace with your SMTP server
        $mail->SMTPAuth = true;
        $mail->Username = 'your_email@example.com'; // Your email
        $mail->Password = 'your_password'; // Your email password
        $mail->SMTPSecure = PHPMailer::ENCRYPTION_STARTTLS;
        $mail->Port = 587;

        // Email settings
        $mail->setFrom('your_email@example.com', 'Your Name');
        $mail->addAddress($email, $name); // Send to the user's email address
        $mail->Subject = 'Thank you for contacting us!';
        
        // Email body content
        $mail->isHTML(true);
        $mail->Body = "<h1>Hello $name,</h1><p>Thank you for reaching out! We will get back to you soon.</p>";
        $mail->AltBody = "Hello $name, \nThank you for reaching out! We will get back to you soon.";

        // Send email
        $mail->send();
        echo "Email has been sent successfully to $email";
    } catch (Exception $e) {
        echo "Message could not be sent. Mailer Error: {$mail->ErrorInfo}";
    }
} else {
    echo "Invalid request.";
}
?>

</body>
</html>
`
},



],
"coursename": "PHP Mail (day-16)"
},


{
  "id": 15,
  "html_title": "php-mongodb",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `install`,
      "bootstrap_title_desc": `composer require mongodb/mongodb
`
},

{
  "bootstrap_title_heading": `restart server`,
  "bootstrap_title_desc": `Restart your web server:

sudo service apache2 restart
`
},


{
  "bootstrap_title_heading": `mongodb_connect.php`,
  "bootstrap_title_desc": `<?php
require 'vendor/autoload.php'; // Include Composer's autoloader if using Composer

use MongoDB\Client;

// Connect to MongoDB
$client = new Client("mongodb://localhost:27017");

// Select a database and collection
$database = $client->selectDatabase("test_db");
$collection = $database->selectCollection("users");

echo "Connected to MongoDB!";


<?php
require 'mongodb_connect.php';

$insertResult = $collection->insertOne([
    'name' => 'John Doe',
    'email' => 'john@example.com'
]);

echo "Inserted document with ID: " . $insertResult->getInsertedId();
?>
?>

`
},


{
  "bootstrap_title_heading": ` Read (Retrieve) Data`,
  "bootstrap_title_desc": `<?php
require 'mongodb_connect.php';

$cursor = $collection->find();

foreach ($cursor as $document) {
    echo "ID: " . $document['_id'], "<br>";
    echo "Name: " . $document['name'], "<br>";
    echo "Email: " . $document['email'], "<br><br>";
}
?>
`
},

{
  "bootstrap_title_heading": ` Update Data`,
  "bootstrap_title_desc": `<?php
require 'mongodb_connect.php';

$updateResult = $collection->updateOne(
    ['name' => 'John Doe'],  // Filter criteria
    ['$set' => ['email' => 'john.doe@example.com']] // Update operation
);

echo "Matched " . $updateResult->getMatchedCount() . " document(s)<br>";
echo "Modified " . $updateResult->getModifiedCount() . " document(s)";
?>
`
},


{
  "bootstrap_title_heading": `Delete Data`,
  "bootstrap_title_desc": `<?php
require 'mongodb_connect.php';

$deleteResult = $collection->deleteOne(['name' => 'John Doe']);

echo "Deleted " . $deleteResult->getDeletedCount() . " document(s)";
?>
`
},








],
"coursename": "PHP Mongo (day-17)"
},


{
  "id": 16,
  "html_title": "user-login",
  "bootstrap_head_desc": [
    {
      "bootstrap_title_heading": `create database`,
      "bootstrap_title_desc": `CREATE DATABASE user_db;

USE user_db;

CREATE TABLE users (
    id INT AUTO_INCREMENT PRIMARY KEY,
    username VARCHAR(50) NOT NULL UNIQUE,
    email VARCHAR(100) NOT NULL UNIQUE,
    password VARCHAR(255) NOT NULL
);

`
},

{
  "bootstrap_title_heading": `connect database`,
  "bootstrap_title_desc": `<?php
$servername = "localhost";
$username = "your_db_username";
$password = "your_db_password";
$dbname = "user_db";

$conn = new mysqli($servername, $username, $password, $dbname);

if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
?>


`
},

{
  "bootstrap_title_heading": `register.php`,
  "bootstrap_title_desc": `<?php
include 'db.php';

if ($_SERVER["REQUEST_METHOD"] == "POST") {
    $username = $_POST['username'];
    $email = $_POST['email'];
    $password = password_hash($_POST['password'], PASSWORD_DEFAULT);

    $stmt = $conn->prepare("INSERT INTO users (username, email, password) VALUES (?, ?, ?)");
    $stmt->bind_param("sss", $username, $email, $password);

    if ($stmt->execute()) {
        echo "Registration successful!";
    } else {
        echo "Error: " . $stmt->error;
    }

    $stmt->close();
}
?>

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Register</title>
</head>
<body>
    <h2>Register</h2>
    <form method="POST" action="register.php">
        Username: <input type="text" name="username" required><br>
        Email: <input type="email" name="email" required><br>
        Password: <input type="password" name="password" required><br>
        <button type="submit">Register</button>
    </form>
</body>
</html>



`
},


{
  "bootstrap_title_heading": `login.php`,
  "bootstrap_title_desc": `<?php
include 'db.php';
session_start();

if ($_SERVER["REQUEST_METHOD"] == "POST") {
    $username = $_POST['username'];
    $password = $_POST['password'];

    $stmt = $conn->prepare("SELECT id, password FROM users WHERE username = ?");
    $stmt->bind_param("s", $username);
    $stmt->execute();
    $stmt->store_result();
    
    if ($stmt->num_rows > 0) {
        $stmt->bind_result($id, $hashed_password);
        $stmt->fetch();

        if (password_verify($password, $hashed_password)) {
            $_SESSION['user_id'] = $id;
            echo "Login successful!";
        } else {
            echo "Invalid password.";
        }
    } else {
        echo "No user found with that username.";
    }

    $stmt->close();
}
?>

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Login</title>
</head>
<body>
    <h2>Login</h2>
    <form method="POST" action="login.php">
        Username: <input type="text" name="username" required><br>
        Password: <input type="password" name="password" required><br>
        <button type="submit">Login</button>
    </form>
</body>
</html>
`
},


{
  "bootstrap_title_heading": `logout.php`,
  "bootstrap_title_desc": `<?php
session_start();
session_unset();
session_destroy();
header("Location: login.php");
exit();
?>
`
},












],
"coursename": "PHP Mongo (day-17)"
},
























]
export default PhpJson