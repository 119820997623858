import React from 'react'
import './Steps.scss'

const Steps = ({batch}) => {
  return (
    <div className='step-apply'>
    
    <h2 className='heads'>Steps For Apply</h2>

    <div className='step1'>

    <div className='circle1'>01</div>
<div className='high'>

<p><span>step 1</span>: Click On {batch.includes('internship')?'register to join':' register to Enroll'} Button</p>
</div>
    <div className='circle2'></div>
    
    </div>

    <div className='step1'>

    <div className='circle1'>02</div>
  
<div className='high'>

<p>  <span>step 2</span>: fill the form for registration and pay registration amount</p>
</div>
    <div className='circle2'></div>
    
    </div>

    <div className='step1'>

    <div className='circle1'>03</div>
  
<div className='high'>

<p>  <span>step 3</span>: You will get an email after successfully registration</p>
</div>
    <div className='circle2'></div>
    
    </div>

    <div className='step1'>

    <div className='circle1'>04</div>
  
<div className='high'>

<p>  <span>step 4</span>: now wait for a call from our HR team.</p>
</div>
    <div className='circle2'></div>
    
    </div>
    
    </div>
  )
}

export default Steps