import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Typewriter from "typewriter-effect";
import "./Collage.scss"
import Col from './Json';

const Collage = ({main}) => {
  return (
    <div className={`collage-main ${main?'collage-ok':''}`}>

     {!main && <div className='slider-student'>

<Typewriter
              options={{
                strings: ['<span>Running Internship From below Colleges</span>'],
                autoStart: true,
                loop: true,
              }}
            
 

/>

</div>
            }


{main && <h2>students, Coming from</h2>}

<Carousel
  additionalTransfrom={0}
  arrows
  autoPlay
  autoPlaySpeed={2400}
  centerMode={false}
  className=""
  dotListClass=""
 
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
removeArrowOnDeviceType={["tablet", "mobile"]}
swipeable={true}
draggable={false}
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 4,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 2
      },
      items:2,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 3,
      partialVisibilityGutter: 30
    }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=""
  slidesToSlide={3}
  pauseOnHover={false}

>


{Col.map((Item) => {
  return(

    <div className='collage-contain' key={Item.userid}>

    <img src={Item.collage_img} alt=""  className={main?'sml':'col-img'}/>
    <h3 className={main?'colu':'col-name'}>- {Item.collage_name} -</h3>

</div>

  )
})}
    

  

   

 
</Carousel>
    </div>
  )
}

export default Collage