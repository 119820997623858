import "./Review.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Rev from "./Json";




const Review = ({show,batch}) => {




  
  
   
    return <>

  
    <div className={`review-slider ${show?'review-ok':''}`}>

   {!show && <div className="studentb">
   What Students Say About Us
    </div>}

   {show && <h2>Reviews from Our {batch.includes('internship')?'Interns':'Students'}</h2>}
      
    <Carousel
   autoPlay={true}
   autoPlaySpeed={2000}
   centerMode={false}
   removeArrowOnDeviceType={["tablet", "mobile"]}
  transitionDuration={500}
 
 
 
   draggable
   focusOnSelect={false}
   infinite={true}
  
   keyBoardControl
   pauseOnHover ={true}
 
  
 
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 3
    },
    mobile: {
      breakpoint: {
        max: 700,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 700
      },
      items: 2
    }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots ={false}
  sliderClass=""
  slidesToSlide={1}
  swipeable={true}
>

        {Rev
        .map((Item)=>{
            return(
                <>

                <div className="review-main" key={Item.userid}>




                <div  className="borderb">
     
     <img src={Item.student} height={"100%"} width={"100%"} className="imageb" alt=''/>
     
     </div>

     <div className="review-desc">{Item.review_decs && Item.review_decs.length>180 ? `${Item.review_decs.slice(0,180)}...`:Item.review_decs}</div>

   

     <div className="review-name">- {Item.studentname}</div>


                </div>


      

              </>
             
             
            )

        })}

     

 
 
</Carousel>
</div>

   

    </>
}

export default Review;
