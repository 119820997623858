import React, { useContext } from 'react'
import './Internship.scss'
import MyContext from '../../../Uttis/MyContext'

const Internship = () => {

  const{select} = useContext(MyContext)
  return (
    
    <h1 className="intern-main">Choose {select==='internship' ?'a Domain':'a Course'} for {select==='internship'?' Internship':'Training'}</h1> 
 )
}

export default Internship