

const ExpressJson = [

 {
"id": 1,


"html_title": "intro",


"bootstrap_head_desc": [
 

{
"bootstrap_title_heading": "What is Express Js?",

"bootstrap_title_desc": `Express is a fast web framework of Node.js.
that helps manage a server and routes.
It can be used to design single-page, multi-page and hybrid web applications.
It allows to setup middlewares to respond to HTTP Requests.
`,

},

{
"bootstrap_title_heading": "besic syntex",

"bootstrap_title_desc": `const express = require('express')
const app = express()


app.get('/', (req, res) => {
res.send('Hello World!')
})

app.listen(3000, () => {
console.log("server connected")
})

//Run the app with the following command
//node app.js`,
}

 


],
 
"coursename": "Ej Intro(day-1)",


},



{
"id": 2,


"html_title": "install-express-js",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Installation",


"bootstrap_title_desc": `Use the npm init command to create a package.
json file for your application.

-- npm init --

Now install Express

--- npm install express ---`,

},

{
"bootstrap_title_heading": " Run Hello World!",





"bootstrap_title_desc": `//index.js
const express = require('express')
const app = express()


app.get('/', (req, res) => {
res.send('Hello World!')
})

app.listen(3000, () => {
console.log("server connected")
})

//Run the app with the following command
//node app.js`,

},


],


"coursename": "Express-Install",


    },

    {
      "id": 3,
  
  
      "html_title": "Ui-express-js",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "index.js",


  


"bootstrap_title_desc": `const express = require('express');
const path = require('path');
const app = express();

app.use(express.static(path.join(__dirname, 'public')));

app.get('/', (req, res) => {
  res.sendFile(path.join(__dirname, 'public', 'index.html'));
});

// Start the server
app.listen(3034, () => {
  console.log('server connected');
});
`,

        },

        {
"bootstrap_title_heading": "folder",


  


"bootstrap_title_desc": `your-project-directory/
├── node_modules/
├── public/
│   └── index.html
├── app.js
├── package.json
└── package-lock.json
          `,
          
                  },

      
      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express-UI",
  
  
    },

    
    {
      "id": 4,
  
  
      "html_title": "basic-routing",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "Basic routing",


  


"bootstrap_title_desc": `app.METHOD(PATH, HANDLER)
GET, POST

GET and POST both are two common HTTP requests used for building 
REST API's. GET requests are used to send only limited amount of
data because data is sent into header while POST requests are used
to send large amount of data because data is sent in the body.

app is an instance of express.
METHOD is an HTTP request method, in lowercase.
PATH is a path on the server.
HANDLER is the function executed when the route is matched.

app.get('/', (req, res) => {
  res.send('Hello World!')
})

app.post('/', (req, res) => {
  res.send('Got a POST request')
})

app.put('/user', (req, res) => {
  res.send('Got a PUT request at /user')
})


app.delete('/user', (req, res) => {
  res.send('Got a DELETE request at /user')
})

`,


        },


      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express Basic routing",
  
  
    },

    {
      "id": 5,
  
  
      "html_title": "express-usage",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "1. Routing",


  


"bootstrap_title_desc": `Routing – Define and manage routes for different HTTP methods (GET, POST, PUT, DELETE).

Middleware – Use middleware for tasks like parsing request bodies, logging, and handling errors.

Handling Form Data and JSON – Parse incoming request bodies in various formats (e.g., JSON, URL-encoded).

Serving Static Files – Serve static assets such as HTML, CSS, JavaScript, and images.

Template Engines – Render dynamic HTML using template engines like Pug, EJS, or Handlebars.

RESTful APIs – Create APIs for CRUD operations (Create, Read, Update, Delete).

Error Handling – Implement custom error-handling mechanisms.

Session Management – Manage user sessions and cookies.

Authentication – Integrate authentication systems (e.g., with passport.js or express-session).

Database Integration – Connect to databases like MongoDB, MySQL, PostgreSQL using ORMs or database drivers.

Real-Time Applications – Build real-time apps with libraries like Socket.io for features like live chat or notifications.

File Uploads – Handle file uploads using middleware such as multer.

Security Features – Implement security measures like input validation, sanitization, and HTTPS support.

Proxy and Reverse Proxy – Use Express.js as a proxy or reverse proxy server.

API Rate Limiting – Control the number of requests from clients to prevent abuse using middleware.

Logging and Monitoring – Implement logging with middleware such as morgan for monitoring requests and responses.`,


        },



      
      ],
  

      "coursename": "Ej Usage (day-2)",
  
  
    },


    {
      "id": 5,
  
  
      "html_title": "express-storage",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "localStorage:Client-Side Storage",


  


"bootstrap_title_desc": `Purpose: localStorage is designed to store data on the client side that persists even after the browser is closed. It's an excellent choice for data that needs to be retained across multiple sessions.

Capacity: localStorage offers substantial storage space, typically up to 10MB per domain, which is sufficient for most applications.

Persistence: Data stored in localStorage remains available until explicitly deleted by the user or the application. This makes it ideal for storing user preferences, like theme settings, that should persist across different visits to the site.

Example: Suppose you have a web application that offers both light and dark modes. You can use localStorage to save the user's preference so that the next time they visit, the site automatically loads in their chosen mode.

// Save the user's theme preference
localStorage.setItem('theme', 'dark');

// Retrieve and apply the theme preference
const userTheme = localStorage.getItem('theme');
if (userTheme) {
  document.body.classList.add(userTheme);
}`,



        },


        {
          "bootstrap_title_heading": "sessionStorage: Temporary Session-Based Storage",
          
          
            
          
          
          "bootstrap_title_desc": `Purpose: sessionStorage also stores data on the client side, but it is limited to the duration of the page session. This means the data is cleared when the user closes the browser tab or window.

Capacity: Similar to localStorage, sessionStorage provides around 5MB of storage per domain. Although the capacity is smaller, it's often sufficient for temporary data.

Persistence: The key difference between sessionStorage and localStorage is persistence. sessionStorage data is only available for the duration of the page session, making it suitable for storing temporary data that doesn't need to persist beyond the current session.

Example: Imagine a multi-step form where users input data across several pages. You can use sessionStorage to temporarily store the form data as the user progresses through the steps. This ensures that if they accidentally reload a page, they don’t lose their progress.

// Save form data temporarily in sessionStorage
sessionStorage.setItem('step1Data', JSON.stringify({ name: 'John Doe', age: 30 }));

// Retrieve the saved data
const step1Data = JSON.parse(sessionStorage.getItem('step1Data'));
console.log(step1Data); // Output: { name: 'John Doe', age: 30 }`,
          
          
          
                  },


                  
        {
          "bootstrap_title_heading": "Cookies: Small, Persistent Storage with Server Interaction",
          
          
            
          
          
          "bootstrap_title_desc": `Purpose: Cookies are used to store small pieces of data that need to persist across sessions and can be sent with HTTP requests to the server. They are often used for tracking user sessions, storing authentication tokens, and remembering user settings.

Capacity: Cookies are much smaller in capacity compared to localStorage and sessionStorage, with a limit of 4KB per cookie. However, multiple cookies can be stored, each with this limit.

Persistence: Cookies have a configurable expiration time. They can either expire at the end of a session or persist for a specified duration. This flexibility allows cookies to be used for both short-term and long-term storage.

Example: A common use of cookies is to store a user’s login token, which allows the user to stay logged in across sessions without having to re-enter their credentials every time they visit the site.
// Set a cookie with an expiration date
document.cookie = "username=JohnDoe; expires=Fri, 31 Dec 2024 23:59:59 GMT; path=/";

// Retrieve the cookie value
const cookies = document.cookie.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=');
    acc[key] = value;
    return acc;
}, {});
console.log(cookies.username); // Output: JohnDoe`,
          
          
          
                  },

      ],
 
      "coursename": "Express Storage",
  
  
    },

    {
      "id": 6,
  
  
      "html_title": "express-api",
  
  
      "bootstrap_head_desc": [
        {
          "bootstrap_title_heading": "data.json",
          
          
            
          
          
          "bootstrap_title_desc": `//data.json
[
{
"id": 1,
"home_page_route_category_page_img": "/category/banner1/prod1.png",
"product_container": [
{
"id": 1,
"size_main": [
{ 
"id":1,
"size": "S"
},
{
"id":2,
"size": "M"
}
 
],
"imgs": "/products/track_pents/track1/track1.png",
"first": "/products/track_pents/track1/track1-1.png",
"second": "/products/track_pents/track1/track1-2.png",
"third": "/products/track_pents/track1/track1-3.png",
"product_name": "Black Night Track Pant",
"quantity": 1,
"single_product_category": "black-night-track-pant",
"product_price": 1200,
"rating": 4.9,
"count": 200,
"product_price_deleted": 2000,
"product_description": "Care Instructions:\n\n- Gentle machine wash or hand wash in cold water.\n- Use mild detergent.\n- Air dry or tumble dry on low heat.\n- Iron on low temperature if needed.\n\nVHX - A FTNESS VIEW"
}

],
"product_category": "track-pants",
"product_heading": "Track Pants"
},
]`,
        
        
                  },
        
        {
"bootstrap_title_heading": "index.js",


  


"bootstrap_title_desc": `//index.js
const express = require('express')
const app = express()
const fs = require('fs');
const path = require('path');
app.use(express.static(path.join(__dirname, 'assets')));
const cors = require('cors');
app.use(cors());



app.get('/api', (req, res) => {
  const filePath = path.join(__dirname, 'data.json');
  
  fs.readFile(filePath, 'utf8', (err, data) => {
  if (err) {
  console.error(err);
  return res.status(500).json({ error: 'Internal Server Error' });
  }
  
  const jsonData = JSON.parse(data);


  const updatedJson = jsonData.map(item => {
    if (item.home_page_route_category_page_img) {
      item.home_page_route_category_page_img = 'http://' + req.get('host') + item.home_page_route_category_page_img;
    }

    item.product_container = item.product_container.map(product => {
      if (product.imgs) {
        product.imgs = 'http://' + req.get('host') + product.imgs;
      }

      if (product.side_img) {
        product.side_img = product.side_img.map(a => {
          if (a.inimg) {
            a.inimg = 'http://' + req.get('host') + a.inimg;
          }
          return a;
        });
      }

      return product;
    });

    return item;
  });

  res.json({ success: true, data: updatedJson });
});
});



app.listen(3034, () => {
console.log("server connected")
})`,


        },

     

        {
"bootstrap_title_heading": "App.js",


  


"bootstrap_title_desc": `//App.js
import React, { useState, useEffect } from 'react';

const App = () => {
const [data, setData] = useState([]);

useEffect(() => {
fetchData();
}, []);

const fetchData = async () => {
try {
const response = await fetch('http://localhost:3034/api');
const jsonData = await response.json();
setData(jsonData.data);
} catch (error) {
alert('Error fetching data:', error);
}
};

return (
<div>
<h2>Data List</h2>
<ul>
{data.map(item => (
<li key={item.id}>
<strong>ID:</strong> {item.id}, <strong>Name:</strong> {item.name}, <strong>Pincode:</strong> {item.pincode}
</li>
))}
</ul>
</div>
);
};

export default App;
`,


        },


      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express API",
  
  
    },

  

    {
      "id": 6,
  
  
      "html_title": "express-register",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "Fontend",


  


"bootstrap_title_desc": `//RegisterForm.js
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const RegisterForm = () => {
const formik = useFormik({
initialValues: {
name: '',
email: '',
mobile: '',
password: '',
},
validationSchema: Yup.object({
name: Yup.string()
.required('Name is required')
.matches(/^([^0-9]*)$/, "Don't allow Numeric Value"),
email: Yup.string()
.required('Email is required')
.email('Enter a valid email'),
mobile: Yup.string()
.required('Mobile number is required')
.matches(/^[0-9]{10}$/, 'Mobile number is not valid'),
password: Yup.string()
.required('Password is required')
.min(8, 'Password must be at least 8 characters')
.max(12, 'Password must be at most 12 characters'),
}),
onSubmit: async (values, { resetForm }) => {
try {
const response = await fetch('http://localhost:3034/register', {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(values),
});


alert('Registration successful');
resetForm();


});

return (
<div>
<h2>Register</h2>
<form onSubmit={formik.handleSubmit}>
<label>
Name:
<input
type="text"
name="name"
value={formik.values.name}
onChange={formik.handleChange}
/>
</label>
{formik.touched.name && formik.errors.name && (
<div>{formik.errors.name}</div>
)}

<label>
Email:
<input
type="email"
name="email"
value={formik.values.email}
onChange={formik.handleChange}
/>
</label>
{formik.touched.email && formik.errors.email && (
<div>{formik.errors.email}</div>
)}

<label>
Mobile:
<input
type="text"
name="mobile"
value={formik.values.mobile}
onChange={formik.handleChange}
/>
</label>
{formik.touched.mobile && formik.errors.mobile && (
<div>{formik.errors.mobile}</div>
)}

<label>
Password:
<input
type="password"
name="password"
value={formik.values.password}
onChange={formik.handleChange}
/>
</label>
{formik.touched.password && formik.errors.password && (
<div>{formik.errors.password}</div>
)}

<button type="submit">Register</button>
</form>
</div>
);
};
    
export default RegisterForm;`,


        },

        {
"bootstrap_title_heading": "Backend",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const app = express();
const cors = require('cors'); //install npm i cors
        
app.use(express.json()); // To parse JSON bodies
         
app.use(cors());

//get register data

app.post('/register', (req, res) => {
const { name, email, mobile, password } = req.body;




console.log('User Registration Data:', { name, email, mobile, password });

});

app.listen(3034, () => {
console.log('Server connected');
});`,


        },

     


      

        
      
  

        
      
  
  
      
      ],

  
      "coursename": "Ej Register(day-3)",
  
  
    },


    {
      "id": 7,
  
  
      "html_title": "express-smtp",
  
  
      "bootstrap_head_desc": [
        {
"bootstrap_title_heading": "Fontend",


  


"bootstrap_title_desc": `//RegisterForm.js
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const RegisterForm = () => {
const formik = useFormik({
initialValues: {
name: '',
email: '',
mobile: '',
password: '',
},
validationSchema: Yup.object({
name: Yup.string()
.required('Name is required')
.matches(/^([^0-9]*)$/, "Don't allow Numeric Value"),
email: Yup.string()
.required('Email is required')
.email('Enter a valid email'),
mobile: Yup.string()
.required('Mobile number is required')
.matches(/^[0-9]{10}$/, 'Mobile number is not valid'),
password: Yup.string()
.required('Password is required')
.min(8, 'Password must be at least 8 characters')
.max(12, 'Password must be at most 12 characters'),
}),
onSubmit: async (values, { resetForm }) => {
try {
const response = await fetch('http://localhost:3034/register', {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(values),
});

const data = await response.json();

if (data.success) {
  alert(data.message);
  resetForm();
} else {
  alert(data.error);
}






});

return (
<div>
<h2>Register</h2>
<form onSubmit={formik.handleSubmit}>
<label>
Name:
<input
type="text"
name="name"
value={formik.values.name}
onChange={formik.handleChange}
/>
</label>
{formik.touched.name && formik.errors.name && (
<div>{formik.errors.name}</div>
)}

<label>
Email:
<input
type="email"
name="email"
value={formik.values.email}
onChange={formik.handleChange}
/>
</label>
{formik.touched.email && formik.errors.email && (
<div>{formik.errors.email}</div>
)}

<label>
Mobile:
<input
type="text"
name="mobile"
value={formik.values.mobile}
onChange={formik.handleChange}
/>
</label>
{formik.touched.mobile && formik.errors.mobile && (
<div>{formik.errors.mobile}</div>
)}

<label>
Password:
<input
type="password"
name="password"
value={formik.values.password}
onChange={formik.handleChange}
/>
</label>
{formik.touched.password && formik.errors.password && (
<div>{formik.errors.password}</div>
)}

<button type="submit">Register</button>
</form>
</div>
);
};
    
export default RegisterForm;`,


        },



        {
"bootstrap_title_heading": "Backend",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const app = express();
const cors = require('cors');
const nodemailer = require('nodemailer');
require('dotenv').config(); // Load environment variables from .env file



app.use(cors());
app.use(express.json()); // To parse JSON bodies
         
 


app.post('/register', async(req, res) => {
const { name, email, mobile, password } = req.body;

// Perform validation or database operations here

// For now, just log the user data
console.log('User Registration Data:', { name, email, mobile, password });


  // Create a Nodemailer transporter
  const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
      user: process.env.EMAIL_USER,
      pass: process.env.EMAIL_PASS,
    },
  });


  // Define email options
  const mailOptions = {
    from: process.env.EMAIL_USER,
    to: email,
    subject: 'Welcome to VHX View',
    html: "
      <p>Hello +req.body.name,</p>
      <p>Thank you for registering with VHX View. We are excited to have you on board!</p>
      <p>Best regards,</p>
      <p>VHX View Team</p>
    ",


  };


  try {

    const info =  await transporter.sendMail(mailOptions);
    console.log('Email sent:', info.response);

    // Send a JSON response to the client
    res.json({ success: true, message: 'Registration successful. Welcome email sent.' });
    
  } catch (error) {
console.error('Email Error:', error);

// Send a JSON response to the client if sending the email fails
res.status(500).json({ success: false, error: 'Failed to send welcome email.' });
}



// res.json({ success: true, message: 'Registration successful' });
});

app.listen(3034, () => {
console.log('Server connected');
});`,


        },


        {
"bootstrap_title_heading": "Installation",


  


"bootstrap_title_desc": `Install Nodemailer npm for smtp

-- npm i nodemailer

install env for envimport

-- npm i dotenv

for password generate

step-1:go to manage profile

step-2:go to security option

step-3:scroll and click on two step password

step-4:scroll and click on app password

step-5:type smtp
`,


        },

        {
"bootstrap_title_heading": ".env",


  


"bootstrap_title_desc": `//.env
EMAIL_USER="veer2238rajput@gmail.com"
EMAIL_PASS="rvro XXXX XXXX XXXX"
`,


        },

     


      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express SMTP Mail",
  
  
    },

    
    {
      "id": 7,
  
  
      "html_title": "express-mongo",
  
  
      "bootstrap_head_desc": [
  





       

        {
"bootstrap_title_heading": "create account",


  


"bootstrap_title_desc": `create one account in mongodb
go to mongo atlas official site: https://www.mongodb.com/atlas/database

-click on try free option

-register and login in that

- now have to create one database

-you will get one link for connect

-install npm

-- npm i mongoose

-- npm i body-parser`
,


        },

        {
"bootstrap_title_heading": "express.js",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const cors = require('cors');
const mongoose = require('mongoose')
const bodyParser = require('body-parser')
const nodemailer = require('nodemailer');
require('dotenv').config(); // Load environment variables from .env file



const app = express();

mongoose
.connect(
  "mongodb+srv://veer2238rajput:STrgrNlEXyfMZHBs@cluster0.3chkue4.mongodb.net/Contact?retryWrites=true&w=majority"
)
.then(() => console.log("mongodb connected"))
.catch((err) => console.log("mongo error", err));

const registerSchema = new mongoose.Schema({
name: {
type: String,
require: true,
},
email: {
type: String,
require: true,
},
mobile: {
type: String,
require: true,
},
password: {
type: String,
require: true,
},
});



const User = mongoose.model("register", registerSchema);


app.use(cors());
app.use(bodyParser.json());

app.post("/register", async (req, res) => {
 const { name, email, mobile,password } = req.body;



// add data
const result = await User.create({
name,
email,
mobile,
password,

});

console.log(result);

// Create a Nodemailer transporter
const transporter = nodemailer.createTransport({
service: 'gmail',
auth: {
user: process.env.EMAIL_USER,
pass: process.env.EMAIL_PASS,
},
});




// Define email options
const mailOptions = {
from: process.env.EMAIL_USER,
to: email,
cc:"psandhya791@gmail.com",
subject: 'Welcome to VHX View',
html: '
<p>Hello {name}</p>
<p>Thank you for registering with VHX View. We are excited to have you on board!</p>
<p>Best regards,</p>
<p>VHX View Team</p>
<img src="https://i.ibb.co/qnVVcMk/digital-camera-photo-1080x675.jpg">
',


};



const info =  await transporter.sendMail(mailOptions);
console.log('Email sent:', info.response);










// console.log('User Registration Data:', { name, email, mobile, password });

});

app.listen(3034, () => {
console.log('Server connected');
});`
,


        },

      

     


      

        
      
  

        
      
  
  
      
      ],
  

  
      "coursename": "Ej Mongo (day-4)",
  
  
    },


    {
      "id": 8,
  
  
      "html_title": "express-response",
  
  
      "bootstrap_head_desc": [
  





       

        {
"bootstrap_title_heading": "register frontend",

          
  
          
          
          "bootstrap_title_desc": `
 onSubmit: async(values, {resetForm}) => {


 const response = await fetch("http://localhost:3034/register",{
        method: 'POST',
        headers: {
'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const data = await response.json();


 if(data.success){

  alert(data.message)
 
resetForm()

  RegisterHide()
 }else{
    alert(data.eoor)
 }

    }, 
  
  
  })
  
  //your return content`
,


        },
      

        {
"bootstrap_title_heading": "express.js",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const app = express();
const cors = require('cors'); 
const mongoose = require('mongoose')
const nodemailer = require('nodemailer');
require('dotenv').config(); // Load environment variables from .env file
app.use(express.json()); // To parse JSON bodies
         
app.use(cors());

mongoose
.connect(
  "mongodb+srv://hundlanijay:hVFEqU8iumiSowXL@registerdata.pqv1sbi.mongodb.net/?retryWrites=true&w=majority"
)
.then(() => console.log("mongo connected"))
.catch((err) => console.log("mongo error", err));


const registerSchema = new mongoose.Schema({
  name: {
  type: String,
  require: true,
  },
  email: {
  type: String,
  require: true,
  },
  mobile: {
  type: String,
  require: true,
  },
  password: {
  type: String,
  require: true,
  },
  });

  const User = mongoose.model("register", registerSchema);

//get register data

app.post('/register', async(req, res) => {
const { name, email, mobile, password } = req.body;

try {
  const result = await User.create({
    name,
      email,
      mobile,
      password,
      
      });
      console.log('User Registration Data:', result);
   

// add data

  
  

 // Create a Nodemailer transporter
 const transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: process.env.EMAIL_USER,
    pass: process.env.EMAIL_PASS,
  },
});




 // Define email options
 const mailOptions = {
  from: process.env.EMAIL_USER,
  to: email,
  cc:"psandhya791@gmail.com",
  subject: 'Welcome to VHX View',
  html: '
    <p>Hello '$name}'</p>
    <p>Thank you for registering with VHX View. We are excited to have you on board!</p>
    <p>Best regards,</p>
    <p>VHX View Team</p>
    <img src="https://i.ibb.co/qnVVcMk/digital-camera-photo-1080x675.jpg">
  ',


};



const info =  await transporter.sendMail(mailOptions);
console.log('Email sent:', info.response);

res.json({ success: true, message: 'Registration successful' });

}

catch (error) {
  // Handle errors
  console.error('Error during registration:', error);
  res.status(500).json({ success: false, error: 'Internal Server Error' });
}


// console.log('User Registration Data:', { name, email, mobile, password });

});

app.listen(3034, () => {
console.log('Server connected');
});`
,


        },

      

     


      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express response",
  
  
    },
  
    {
      "id": 8,
  
  
      "html_title": "express-duplicate-data",
  
  
      "bootstrap_head_desc": [
  





       

        {
          "bootstrap_title_heading": "register frontend",
          
          
  

          
          
          "bootstrap_title_desc": `

const {loader,setLoader} = useContext(MyContext) // update state in provider - const[loader,setLoader] = useEffect(false)
 onSubmit: async(values, {resetForm}) => {
  setLoader(true)


 const response = await fetch("http://localhost:3034/register",{
        method: 'POST',
        headers: {
'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const data = await response.json();


 if(data.success){

  alert(data.message)
 
resetForm()

  RegisterHide()
 }else{
    alert(data.eoor)
 }

 setLoading(false)

    }, 
  
  
  })
  
  //your return content

  <Button>{loader?'wait':'register'}<Button>
  `
,


        },
      

        {
"bootstrap_title_heading": "express.js",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const app = express();
const cors = require('cors'); 
const mongoose = require('mongoose')
const nodemailer = require('nodemailer');
require('dotenv').config(); // Load environment variables from .env file
app.use(express.json()); // To parse JSON bodies
         
app.use(cors());

mongoose
.connect(
  "mongodb+srv://hundlanijay:hVFEqU8iumiSowXL@registerdata.pqv1sbi.mongodb.net/?retryWrites=true&w=majority"
)
.then(() => console.log("mongo connected"))
.catch((err) => console.log("mongo error", err));


const registerSchema = new mongoose.Schema({
  name: {
  type: String,
  require: true,
  },
  email: {
  type: String,
  require: true,
  },
  mobile: {
  type: String,
  require: true,
  },
  password: {
  type: String,
  require: true,
  },
  });

  const User = mongoose.model("register", registerSchema);

//get register data

app.post('/register', async(req, res) => {
const { name, email, mobile, password } = req.body;

try {

  // Check if the user with the given email already exists
  const existingUser = await User.findOne({ email });

  if (existingUser) {
    // If user exists, return an error response
    return res.json({ success: false, error: 'Email already registered, please do login' });
  }
  
  const result = await User.create({
    name,
      email,
      mobile,
      password,
      
      });
      console.log('User Registration Data:', result);
   

// add data

  
  

 // Create a Nodemailer transporter
 const transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: process.env.EMAIL_USER,
    pass: process.env.EMAIL_PASS,
  },
});




 // Define email options
 const mailOptions = {
  from: process.env.EMAIL_USER,
  to: email,
  subject: 'Welcome to VHX View',
  html: '
    <p>Hello '$name}'</p>
    <p>Thank you for registering with VHX View. We are excited to have you on board!</p>
    <p>Best regards,</p>
    <p>VHX View Team</p>
    <img src="https://i.ibb.co/qnVVcMk/digital-camera-photo-1080x675.jpg">
  ',


};



const info =  await transporter.sendMail(mailOptions);
console.log('Email sent:', info.response);

res.json({ success: true, message: 'Registration successful' });

}

catch (error) {
  // Handle errors
  console.error('Error during registration:', error);
  res.status(500).json({ success: false, error: 'Internal Server Error' });
}


// console.log('User Registration Data:', { name, email, mobile, password });

});

app.listen(3034, () => {
console.log('Server connected');
});`
,


        },

      

     


      

        
      
  

        
      
  
  
      
      ],
  

      "coursename": "Ej Duplicate data(day-5)",
  
  
    },


    {
      "id": 9,
  
  
      "html_title": "express-encrypt-password",
  
  
      "bootstrap_head_desc": [
  





       
        {
          "bootstrap_title_heading": "encrypt data",
          
          
            
          
          
          "bootstrap_title_desc": `install npm 

-- npm i bcrypt

const hashedPassword = await bcrypt.hash(password, 10); is using the
bcrypt library to hash the user's plain text password before storing
it in the database

takes the user's plain text password, applies a hash function
 to it with 10 rounds of salting, and stores the resulting
hashed password in the variable hashedPassword. This hashed 
password is then stored in the database, making it more secure
than storing plain text passwords. When checking passwords
during login, you would compare the hashed input password
with the stored hashed password in the database.

Salting is a technique used in password hashing to add
an additional layer of security to stored passwords.

Without Salting:

User 1 password: "password123"
User 2 password: "password123"
If both passwords are hashed without salting,
 they will result in the same hash, making it easier
  for an attacker to identify common passwords.

With Salting:

User 1 password: "password123", salt: "abc123"
User 2 password: "password123", salt: "xyz789"`
          ,
          
        
                  },

      
      

        {
"bootstrap_title_heading": "express.js",


  


"bootstrap_title_desc": `//server.js
const express = require('express');
const app = express();
const cors = require('cors'); 
const mongoose = require('mongoose')
const nodemailer = require('nodemailer');
const bcrypt = require('bcrypt')
require('dotenv').config(); // Load environment variables from .env file
app.use(express.json()); // To parse JSON bodies
         
app.use(cors());

mongoose
.connect(
  "mongodb+srv://hundlanijay:hVFEqU8iumiSowXL@registerdata.pqv1sbi.mongodb.net/?retryWrites=true&w=majority"
)
.then(() => console.log("mongo connected"))
.catch((err) => console.log("mongo error", err));


const registerSchema = new mongoose.Schema({
  name: {
  type: String,
  require: true,
  },
  email: {
  type: String,
  require: true,
  },
  mobile: {
  type: String,
  require: true,
  },
  password: {
  type: String,
  require: true,
  },
  });

  const User = mongoose.model("register", registerSchema);

//get register data

app.post('/register', async(req, res) => {
const { name, email, mobile, password } = req.body;


try {

  // Check if the user with the given email already exists
  const existingUser = await User.findOne({ email });
 

  if (existingUser) {
    // If user exists, return an error response
    return res.json({ success: false, error: 'Email already registered, please do login' });
  }


  const hashedPassword = await bcrypt.hash(password, 10);



  
  
  // add data
const result = await User.create({
    name,
    email,
    mobile,
    password:hashedPassword,
              
    });
              
    console.log(result);
  
   // Create a Nodemailer transporter
   const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
      user: process.env.EMAIL_USER,
      pass: process.env.EMAIL_PASS,
    },
  });
  
  
  
  
   // Define email options
   const mailOptions = {
    from: process.env.EMAIL_USER,
    to: email,
    subject: 'Welcome to VHX View',
    html: "
      <p>Hello '$'{name}</p>
      <p>Thank you for registering with VHX View. We are excited to have you on board!</p>
      <p>Best regards,</p>
      <p>VHX View Team</p>
      <img src="https://i.ibb.co/qnVVcMk/digital-camera-photo-1080x675.jpg">
    ",
  
  
  };
  
  
  
  const info =  await transporter.sendMail(mailOptions);
  console.log('Email sent:', info.response);
  res.json({ success: true, message: 'Registration successful' });
} 




catch (error) {
  console.error('Error during registration:', error);

  res.json({ success: false, error: 'Internal Server Error' });
}








          

          

// console.log('User Registration Data:', { name, email, mobile, password });
          
});
          
app.listen(3034, () => {
console.log('Server connected');
});`
,


        },

      

     


      

        
      
  

        
      
  
  
      
      ],
  
  
  
  
  
  
  
  
  
  
  
  
      "coursename": "Express encrypt password",
  
  
    },


    {
      "id": 9,
  
  
      "html_title": "express-modules",
  
  
      "bootstrap_head_desc": [
  


      
      

        {
"bootstrap_title_heading": "Modules/Contact.js",


  


"bootstrap_title_desc": `const mongoose = require('mongoose');

const contactSchema = new mongoose.Schema({
  name: { type: String, required: true },
  email: { type: String, required: true },
  message: { type: String, required: true },
});

const Contact = mongoose.model('Contact', contactSchema);
module.exports = Contact;
`
,


        },

        {
          "bootstrap_title_heading": "Modules/Register.js",
          
          
            
          
          
          "bootstrap_title_desc": `const mongoose = require('mongoose');

const registerSchema = new mongoose.Schema({
  name: { type: String, required: true },
  email: { type: String, required: true },
  mobile: { type: String, required: true },
  message: { type: String, required: true },
});

const Register = mongoose.model('Register', registerSchema);
module.exports = Register;
`
          ,
          
          
                  },


                  {
                    "bootstrap_title_heading": "Routes/Contact.js",
                    
                    
                      
                    
                    
                    "bootstrap_title_desc": `const express = require('express');
const Contact = require('../Models/Contact');

const app = express();

app.post('/contact', async (req, res) => {
  const { name, email, message } = req.body;

  const exist = await Contact.findOne({ email, message });
  if (exist) {
    return res.status(400).send({ success: false, error: 'You have already contacted us with the same query' });
  }

  const result = await Contact.create({ name, email, message });
  res.json({ success: true, message: 'Thanks for contacting us' });
  console.log(result);
});

module.exports = app;
`
,
                    
                    
                            },


                            
                  {
                    "bootstrap_title_heading": "Routes/Register.js",
                    
                    
                      
                    
                    
                    "bootstrap_title_desc": `const express = require('express');
const Register = require('../Models/Register');

const app = express();

app.post('/register', async (req, res) => {
  const { name, email, mobile, message } = req.body;

  const result = await Register.create({ name, email, mobile, message });
  res.json({ success: true, message: 'Thanks for registering' });
});

module.exports = app;
`
,
                    
                    
                            },

                            {
                              "bootstrap_title_heading": "index.js",
                              
                              
                                
                              
                              
                              "bootstrap_title_desc": `const express = require('express');
const cors = require('cors');
const mongoose = require('mongoose');

const contactRoutes = require('./Routes/Contact');
const registerRoutes = require('./Routes/Register');


const app = express();
app.use(cors());
app.use(express.json());

mongoose
.connect(
  "mongodb+srv://abroadvisa2220:CLeh6tXvWaYkTLAU@cluster0.y89j4.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0"
)
.then(() => console.log("mongodb connected"))
.catch((err) => console.log("mongo error", err));

app.use('/', contactRoutes);
app.use('/', registerRoutes);



app.listen(3034, () => {
  console.log('Server connected');
});
`
          ,
                              
                              
                                      },


      
      ],
  

      "coursename": "Express Modules",
  
  
    },


 

    {
      "id": 10,
  
  
      "html_title": "express-login-jwt",
  
  
      "bootstrap_head_desc": [
  





       
        {
          "bootstrap_title_heading": "login Token",
          
          
            
          
          
          "bootstrap_title_desc": `install npm
-- npm i jsonwebtoken

//previous code
const jwt = require('jsonwebtoken');
//next code
app.post('/login', async (req, res) => {
const { email, password } = req.body;
          
try {
const user = await User.findOne({ email });
          
  if (!user) {
    return res.json({ success: false, error: 'Invalid email' });
  }
          
  const passwordMatch = await bcrypt.compare(password, user.password);
          
  if (!passwordMatch) {
     return res.json({ success: false, error: 'Invalid  password' });
   }
          

  
   const token = jwt.sign({ email }, 'secret-key', { expiresIn: '10h' });
          
   console.log(token)
   console.log(user.name)
   

  
res.json({ success: true,message:"Thanks for login" data: token);
} catch (error) {
 console.error('Error during login:', error);
}


});`
          ,
          
        
       },

       {
        "bootstrap_title_heading": "login frontend",
        
        
          
        
        
        "bootstrap_title_desc": `

const{handleLogin} = useContext(MyContext)

onSubmit: async (values, resetForm) => {
    
const response = await fetch("http://localhost:3034/login", {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(values),
});

const data = await response.json();

alert(data.message)
 handleLogin(data.token)
 resetForm()

} else {
alert(data.error);
}

resetForm();

},`
,


},


 {
"bootstrap_title_heading": "MyContextProvider.jsx",





"bootstrap_title_desc": `//previous code

const[token,setToken] = useState(()=>{
  const storedToken = sessionStorage.getItem('token');
 return storedToken?setToken(storedToken):''
  })


const handleLogin = (newtoken) =>{
setToken(newtoken);
sessionStorage.setItem('token', newtoken);
Navigate('/');
}

const handleLogout = () => {
const confirmed = window.confirm('Are you sure you want to log out?');
if (confirmed) {
sessionStorage.removeItem('token');
window.location.reload()
setToken('');
}
}`

,


},

{
  "bootstrap_title_heading": "Header.jsx",
  
  
  
  
  
  "bootstrap_title_desc": `//previous code
  
 const Token =  sessionStorage.getItem('token');
 const {handleLogout} = useContext(MyContext)

  
  
 return(
// previous code
  {
    !Token?
  <li>login</li>:
  <li onClick={handleLogout}> logout</li>
  }
 )`
  ,
  

  },
  
      ],
  

  
      "coursename": "Ej token-Jwt (day-6)",
  
  
    },

    {
      "id": 19,
  
  
      "html_title": "express-account-data",
  
  
      "bootstrap_head_desc": [

       
        {
          "bootstrap_title_heading": "Backend",
          
          
            
          
          
          "bootstrap_title_desc": `// get account data
app.get('/account-details', async (req, res) => {
try {
const token = req.headers.authorization?.split(' ')[1];
if (!token) {
return res.status(401).json({ error: 'Token not provided' });
}

jwt.verify(token, 'secret-key', async (err, decoded) => {
if (err) {
return res.status(401).json({ merror: 'Invalid token' });
}

const user = await Register.findOne({ email: decoded.email });
if (!user) {
return res.status(404).json({ error: 'User not found' });
}

const accountInfo = {
name: user.name,
email: user.email,
mobile: user.mobile,
};

res.json({ accountInfo:accountInfo });
});
} catch (error) {
console.error('Error fetching cart items:', error);
res.status(500).json({ message: 'Internal Server Error' });
}
});


// ACCOUNT INFORMATION UPDATE 

app.post('/update-account-data', async (req, res) => {
const { name,email,mobile,password } = req.body;


try {
const token = req.headers.authorization?.split(' ')[1];
if (!token) {
return res.status(401).json({success: false,error: 'Token not provided' });
}

jwt.verify(token, 'secret-key', async (err, decoded) => {
if (err) {
return res.status(401).json({success: false, error: 'Invalid token' });
}

const user = await Register.findOne({ email: decoded.email });
if (!user) {
return res.status(404).json({success: false, error: 'User not found' });
}

const hashedPassword = await bcrypt.hash(password, 10);
user.name = name;
user.email = email;
user.mobile = mobile;
user.password=hashedPassword
await user.save();



const accountInfo = {

name: user.name,
email: user.email,
mobile: user.mobile,

};
 

res.json({ success: true, message: 'Thanks Your Information has Been Updated' ,accountInfo:accountInfo});

});
} catch (error) {
console.error('Error fetching user address:', error);
res.status(500).json({ success: false, error: 'Internal Server Error' });
}
 
 });`
,

    
       },


       {
        "bootstrap_title_heading": "frontend",
        
        
          
        
        
        "bootstrap_title_desc": `import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Account.scss';
import MyContext from '../../Context/MyContext';
import LoginError from '../LoginError/LoginError';

const Account = () => {
const { edit, setEdit,token, userdata, setUserdata, loadingin, setLoadingin, setOpenalert, setMessage } = useContext(MyContext);
const [loader, setLoader] = useState(true);
 

useEffect(() => {
const fetchUserData = async () => {
try {
const response = await fetch('http://localhost:3034/api/account-details', {
headers: {
Authorization: 'Bearer '$'{token}',
},
});
const data = await response.json();
setUserdata(data.accountInfo);
sessionStorage.setItem('userdata', JSON.stringify(data.accountInfo));
} catch (error) {
console.error('Failed to fetch account details:', error);
} finally {
setLoader(false);
}
};

fetchUserData();
}, [setUserdata, token]);

if (loader) {
return <div>Loading...</div>;
}

return (
<>
{token ? (
edit ? (
<Formik
initialValues={{
name: userdata.name || '',
email: userdata.email || '',
mobile: userdata.mobile || '',
password: '',
}}
validationSchema={Yup.object({
name: Yup.string().required('Name is required'),
email: Yup.string().email('Invalid email address').required('Email is required'),
mobile: Yup.string().required('Mobile number is required'),
password: Yup.string().required('Password is required'),
})}
onSubmit={async (values) => {
setLoadingin(true);
const response = await fetch('http://localhost:3034/update-account-data', {
method: 'POST',
headers: {
'Content-Type': 'application/json',
Authorization: 'Bearer '$'{token}',
},
body: JSON.stringify(values),
});

const data = await response.json();

if (data.success) {
setOpenalert(true);
setMessage(data.message);
setUserdata(data.accountInfo);
sessionStorage.setItem('userdata', JSON.stringify(data.accountInfo));
setTimeout(() => {
window.location.reload()
}, 2000);
} else {
setOpenalert(true);
setMessage(data.error);
}
setLoadingin(false);
}}
>
{({ isSubmitting }) => (
<Form className='name-main'>
<h2>Update Account Details</h2>
<div className='name-form'>
<div className='name-input'>
<label htmlFor="name">Name:</label>
<Field type="text" name="name" />
<ErrorMessage name="name" component="div" className="error-message" />
</div>
<div className='name-input'>
<label htmlFor="email">Email:</label>
<Field type="text" name="email" />
<ErrorMessage name="email" component="div" className="error-message" />
</div>
<div className='name-input'>
<label htmlFor="mobile">Mobile:</label>
<Field type="text" name="mobile" />
<ErrorMessage name="mobile" component="div" className="error-message" />
</div>
<div className='name-input'>
<label htmlFor="password">Password:</label>
<Field type="password" name="password" placeholder='Enter Updated Password' />
<ErrorMessage name="password" component="div" className="error-message" />
</div>
<button type="submit" disabled={isSubmitting}>
{loadingin ? 'Wait...' : 'Submit'}
</button>
</div>
</Form>
)}
</Formik>
) : (
<div className='account-details'>
<h2>Account Information</h2>
<p><strong>Name:</strong> {userdata && userdata.name}</p>
<p><strong>Email:</strong> {userdata && userdata.email}</p>
<p><strong>Mobile:</strong> {userdata && userdata.mobile}</p>
<p><strong>Password:</strong> ********</p>
<button onClick={() => setEdit(true)}>Edit</button>
</div>
)
) : (
<LoginError title="Account information" />
)}
</>
);
};

export default Account;
`
,


},


{
"bootstrap_title_heading": "LoginError.jsx",





"bootstrap_title_desc": `import React, { useContext } from 'react'
import './LoginError.scss'
import MyContext from '../../Context/MyContext'

const LoginError = ({title}) => {
const{handleLoginOpen} = useContext(MyContext)
return (
<div className='loginerror'>
To View {title} Please Login First
<span onClick={handleLoginOpen}>click here to Login</span>
</div>
)
}

export default LoginError`
,


},





],




"coursename": "Ej account-data (day-7)",


},


{
"id": 6,


"html_title": "express-contacts",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Contact.jsx",





"bootstrap_title_desc": `import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
name: yup.string().required('Name is required'),
email: yup.string().email('Enter a valid email').required('Email is required'),
message: yup.string().required('Message is required').min(5),
phone: yup.number().min(10).required('Phone no. is required'),
});

const Contact = () => {

const[loader,setLoader] = useState(false)

const formik = useFormik({

initialValues: {
name: '',
email: '',
phone: '',
message: '',
},
validationSchema: validationSchema,
onSubmit: async(values,{ resetForm }) => {
setLoader(true)
 const response = await fetch('http://localhost:3034/contact', {
method: 'POST',
body: JSON.stringify(values),
headers: {
'Content-type': 'application/json',
},
});
const data =await response.json()

if(data.success){
 
alert(data.message)
resetForm()
}

setLoader(false)
 
 



},
});


return (
<>
 
<div className='page1' >
<form onSubmit={formik.handleSubmit}>
<TextField
id="name"
name="name"
value={formik.values.name || ''}
onChange={formik.handleChange}
error={formik.touched.name && Boolean(formik.errors.name)}
helperText={formik.touched.name && formik.errors.name}
label="Name"
variant="standard" /><br />


<TextField
id="email"
name="email"

value={formik.values.email || ''}
onChange={formik.handleChange}
error={formik.touched.email && Boolean(formik.errors.email)}
helperText={formik.touched.email && formik.errors.email}
label="Email"
variant="standard" /><br />

<TextField
id="phone"
name="phone"

value={formik.values.phone || ''}
onChange={formik.handleChange}
error={formik.touched.phone && Boolean(formik.errors.phone)}
helperText={formik.touched.phone && formik.errors.phone}
label="Phone"
variant="standard" /><br />


<TextField
id="message"
name="message"
rows={4}
multiline
value={formik.values.message || ''}
onChange={formik.handleChange}
error={formik.touched.message && Boolean(formik.errors.message)}
helperText={formik.touched.message && formik.errors.message}
label="message"
variant="standard" /><br />

<Button variant="contained"disabled={!formik.isValid} className='SM' type='submit' >Send Message</Button>
{loader && <p>loading...</p>}
</form>
</div>

</>
)
}

export default Contact`,


},

{
"bootstrap_title_heading": "index.js(Backend)",





"bootstrap_title_desc": `//index.js
const express = require('express');
const cors = require('cors');
const mongoose = require('mongoose')
const bodyParser = require('body-parser')

const app = express();

mongoose
.connect(
"mongodb+srv://veer2238rajput:STrgrNlEXyfMZHBs@cluster0.3chkue4.mongodb.net/Contact?retryWrites=true&w=majority"
)
.then(() => console.log("mongodb connected"))
.catch((err) => console.log("mongo error", err));

const ContactSchema = new mongoose.Schema({
name: {
type: String,
require: true,
},
email: {
type: String,
require: true,
},
phone: {
type: String,
require: true,
},
message: {
type: String,
require: true,
},
});
 
const User = mongoose.model("demo", ContactSchema);


app.use(cors());
app.use(bodyParser.json());

app.post("/contact", async (req, res) => {
const { name, email, phone,message } = req.body;

try {
const result = await User.create({
name,
email,
phone,
message,

});

res.json({ success: true, message: 'added' });
console.log(result);

} catch (error) {
res.json({ success: false, error: 'not added' });
}






});

app.listen(3034, () => {
console.log('Server connected');
});`,



},















],












"coursename": "Ej Contacts (day-8)",


},

{
"id": 6,


"html_title": "express-contacts-data",


"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Contact-Info.jsx",





"bootstrap_title_desc": `import React, { useState, useEffect } from 'react';


const Contact = () => {
const [loader, setLoader] = useState(false);
const [contacts, setContacts] = useState([]);

useEffect(() => {
const fetchData = async () => {
setLoader(true);
try {
const response = await fetch('http://localhost:3034/contact-info');
const data = await response.json();
if (data.success) {
setContacts(data.data);
} else {
console.error(data.error);
}
} catch (error) {
console.error('Error fetching data:', error);
}
setLoader(false);
};

fetchData();
}, []);

return (
<>
 
<div className='contact-list'>
{contacts.map((item) => (
<div key={item._id}>
<p>Name: {item.name}</p>
<p>Email: {item.email}</p>
<p>Phone: {item.phone}</p>
<p>Message: {item.message}</p>
<hr />
</div>
))}
</div>

</>
);
};

export default Contact;
`,


},

{
"bootstrap_title_heading": "index.js(Backend)",





"bootstrap_title_desc": `//index.js
const express = require('express');
const cors = require('cors');
const mongoose = require('mongoose')
const bodyParser = require('body-parser')

const app = express();

mongoose
.connect(
"mongodb+srv://veer2238rajput:STrgrNlEXyfMZHBs@cluster0.3chkue4.mongodb.net/Contact?retryWrites=true&w=majority"
)
.then(() => console.log("mongodb connected"))
.catch((err) => console.log("mongo error", err));

const ContactSchema = new mongoose.Schema({
name: {
type: String,
require: true,
},
email: {
type: String,
require: true,
},
phone: {
type: String,
require: true,
},
message: {
type: String,
require: true,
},
});
 
const User = mongoose.model("demo", ContactSchema);


app.use(cors());
app.use(bodyParser.json());

app.post("/contact", async (req, res) => {
const { name, email, phone,message } = req.body;

try {

const user = await User.findOne({email,message});
if(user){
return res.json({ success: false, error: 'added already' });
}
const result = await User.create({
name,
email,
phone,
message,

});

res.json({ success: true, message: 'added' });
console.log(result);

} catch (error) {
res.json({ success: false, error: 'not added' });
}






});

app.get("/contact-info", async (req, res) => {
try {
const contacts = await User.find();
res.json({ success: true, data:contacts });
} catch (error) {
res.json({ success: false, error: 'Failed to retrieve contacts' });
}
});

app.listen(3034, () => {
console.log('Server connected');
});`,



},


],

"coursename": "Express Contacts Data",


},





{
"id": 13,


"html_title": "express-upload-file",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "Frontend(UploadForm.jsx)",





"bootstrap_title_desc": `import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const UploadForm = () => {
const initialValues = {
file: null,
};

const validationSchema = Yup.object().shape({
file: Yup.mixed().required('File is required'),
});

const handleSubmit = async (values, { setSubmitting, resetForm }) => {
const formData = new FormData();
formData.append('file', values.file);

try {
await axios.post('http://localhost:9000/upload', formData, {
headers: {
'Content-Type': 'multipart/form-data'
}
});
alert('File uploaded successfully');
resetForm();
} catch (error) {
console.error('Error uploading file:', error);
}
setSubmitting(false);
};

return (
<Formik
initialValues={initialValues}
validationSchema={validationSchema}
onSubmit={handleSubmit}
>
{({ isSubmitting }) => (
<Form>
<Field name="file">
{({ field, form }) => (
<div>
<input
type="file"
onChange={(event) => form.setFieldValue('file', event.currentTarget.files[0])}
onBlur={field.onBlur}
/>
<ErrorMessage name="file" component="div" />
</div>
)}
</Field>

<button type="submit" disabled={isSubmitting}>
Upload
</button>
</Form>
)}
</Formik>
);
};

export default UploadForm;
`
,


},

{
"bootstrap_title_heading": "Backend(index.js)",





"bootstrap_title_desc": `import express from "express";
import cors from "cors";
import bodyParser from "body-parser";
import mongoose from "mongoose";
import dotenv from "dotenv";
import multer from "multer";
import path from "path";

dotenv.config();

const app = express();

// Connect to MongoDB
mongoose
.connect(
"mongodb+srv://veer2238rajput:STrgrNlEXyfMZHBs@cluster0.3chkue4.mongodb.net/Contact?retryWrites=true&w=majority"
)
.then(() => console.log("MongoDB connected"))
.catch((err) => console.error("MongoDB connection error:", err));


const fileSchema = new mongoose.Schema({
filename: String,
path: String,
size: Number,
createdAt: { type: Date, default: Date.now }
});

const File = mongoose.model('File', fileSchema);

// Multer configuration for file upload
const storage = multer.diskStorage({
destination: function (req, file, cb) {
cb(null, 'uploads/');
},
filename: function (req, file, cb) {
cb(null, Date.now() + path.extname(file.originalname));
}
});

const upload = multer({ storage: storage });

// Middleware
app.use(cors());
app.use(bodyParser.json());

// Route for file upload
app.post('/upload', upload.single('file'), async (req, res) => {
const { filename, path, size } = req.file;
const file = new File({
filename,
path,
size
});
try {
await file.save();
res.send('File uploaded successfully');
console.log("File uploaded:", req.file);
} catch (err) {
console.error("Error saving file to database:", err);
res.status(500).send("Error saving file to database");
}
});

// Start the server
app.listen(port, () => {
console.log(Server running on port 9000');
});
`
,


},

{
  "bootstrap_title_heading": "Frontend(UploadForm.js)",
  
  
  
  
  
  "bootstrap_title_desc": `import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const UploadForm = () => {
const initialValues = {
file: null,
file1: null,
};

const validationSchema = Yup.object().shape({
file: Yup.mixed().required('File is required'),
file1: Yup.mixed().required('File1 is required'),
});

const handleSubmit = async (values, { setSubmitting, resetForm }) => {
const formData = new FormData();
formData.append('file', values.file);
formData.append('file1', values.file1);

try {
await axios.post('http://localhost:3034/upload', formData, {
headers: {
'Content-Type': 'multipart/form-data'
}
});
alert('Files uploaded successfully');
resetForm();
} catch (error) {
console.error('Error uploading files:', error);
alert('Error uploading files');
}
setSubmitting(false);
};

return (
<Formik
initialValues={initialValues}
validationSchema={validationSchema}
onSubmit={handleSubmit}
>
{({ setFieldValue, isSubmitting }) => (
<Form>
<div>
<input
type="file"
name="file"
accept="application/pdf" // Add file type restrictions here (e.g., PDF only)
onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
/>
<ErrorMessage name="file" component="div" />
</div>

<div>
<input
type="file"
name="file1"
accept="application/pdf" // Add file type restrictions here (e.g., PDF only)
onChange={(event) => setFieldValue('file1', event.currentTarget.files[0])}
/>
<ErrorMessage name="file1" component="div" />
</div>

<button type="submit" disabled={isSubmitting}>
Upload
</button>
</Form>
)}
</Formik>
);
};

export default UploadForm;
`
,


},

{
  "bootstrap_title_heading": "Backend(index.js)",
  
  
  
  
  
  "bootstrap_title_desc": `const express = require('express');
const cors = require('cors');
const multer = require('multer');
const path = require('path');
const fs = require('fs');

const app = express();

app.use(cors());


const storage = multer.diskStorage({
  destination: (req, file, cb) => {
    cb(null, 'uploads/'); 
  },
  filename: (req, file, cb) => {
    cb(null, Date.now() + path.extname(file.originalname));
  },
});

const upload = multer({ storage });


const uploadDir = 'uploads/';
if (!fs.existsSync(uploadDir)) {
  fs.mkdirSync(uploadDir);
}


app.post('/upload', upload.fields([{ name: 'file' }, { name: 'file1' }]), (req, res) => {
  const file1 = req.files.file[0];
  const file2 = req.files.file1[0];

  const { filename: filename1, path: path1, size: size1 } = file1;
  const { filename: filename2, path: path2, size: size2 } = file2;

  console.log('Files uploaded:', { filename1, path1, size1 }, { filename2, path2, size2 });


  res.send('Files uploaded successfully');
});



app.listen(3034, () => {
  console.log('Server started on http://localhost:3034');
});
`
,


},




],

"coursename": "Ej Upload Files(day-9)",


},

{
"id": 14,


"html_title": "express-get-file",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "Frontend(Show.jsx)",





"bootstrap_title_desc": `import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Show = () => {
const [data, setData] = useState([]);

useEffect(() => {
const fetchData = async () => {
try {
const response = await axios.get('http://localhost:9000/api/data');
setData(response.data);
} catch (error) {
console.error('Error fetching data:', error);
}
};

fetchData();
}, []);

return (
<div>
<h1>Data from MongoDB</h1>
<ul>
{data.map(item => (
<li key={item._id}>

<img src={'http://localhost:9000/$'{item.path}'} alt="Uploaded" style={{ maxWidth: '100px' }} />
</li>
))}
</ul>
</div>
);
};

export default Show;
`
,


},

{
"bootstrap_title_heading": "Backend(index.js)",





"bootstrap_title_desc": `app.get('/api/data', async (req, res) => {
 try {
const data = await File.find(); // Assuming File is your Mongoose model
res.json(data);
 } catch (error) {
console.error('Error fetching data:', error);
res.status(500).send('Error fetching data');
 }
});`
,


},

 


],

"coursename": "Express Get Files",


},

{
"id": 15,


"html_title": "Schedule-mail",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "index.js",





"bootstrap_title_desc": `schedule.scheduleJob('0 0 * * 6,0', async () => {
try {
const users = await File.find();

for (const user of users) {


 
const transporter = nodemailer.createTransport({
service: 'gmail',
auth: {
user: 'veer2238rajput@gmail.com',
pass: 'ngpb hgqv hztj cuuc'
},
});

const mailOptions = {
from: 'veer2238rajput@gmail.com',
to: user.email,
subject: ' V-Ex Tech Solution (Weekend Holiday Notice)',
html: 'hi',
};



const info = await transporter.sendMail(mailOptions);
console.log('Email sent:', info.response);
console.log('holiday emails sent successfully');

}

 
} catch (error) {
console.error("Error sending birthday emails:", error);
}
});`
,


},



 


],

"coursename": "Ej Schedule Mail(day-10)",


},

{
"id": 16,


"html_title": "send-pdf-mail",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "index.js",





"bootstrap_title_desc": `
const mailOptions = {
from: 'veer2238rajput@gmail.com',
to: 'abc@gmail.com',
subject: 'Receipt for Your Registration',
html: '
<div style="background-color: #f3f3f3; padding: 20px;">
<div style="background-color: #ffffff; border-radius: 10px; padding: 20px;">
<p style="color: #333; font-size: 18px;">Dear Zankhna,</p>
<p style="color: #333; font-size: 16px;">Thank you for registering with us!</p>
<p style="color: #333; font-size: 16px;">Below is your receipt:</p>
<hr style="border: 1px solid #ccc;">
<div style="margin-top: 20px;">
<p style="color: #333; font-size: 16px;"><strong>Registration Details:</strong></p>
<ul style="list-style-type: none; padding-left: 0;">
<li><strong>Product:</strong> React.js + Backend</li>
<li><strong>Amount:</strong> Paid</li>
<li><strong>Date:</strong> March 18, 2024</li>
</ul>
</div>
<hr style="border: 1px solid #ccc;">
<p style="color: #333; font-size: 16px;">If you have any questions or concerns, feel free to contact us.</p>
<p style="color: #666; font-size: 16px;">Best regards,</p>
<p style="color: #666; font-size: 16px;">V-Ex Tech Solution Team</p>
<div style="margin-top: 20px;">
<a href="https://v-extechsolution.in" style="color: #3498db; font-size: 16px;">v-extechsolution.in</a><br>
<a href="mailto:veeragraval@v-extechsolution.in" style="color: #3498db; font-size: 16px;">veeragraval@v-extechsolution.in</a><br>
<a href="tel:9664768292" style="color: #3498db; font-size: 16px;">+91 9664768292</a>
</div>
<div style="margin-top: 20px;">
<a href="https://www.linkedin.com/company/v-ex-tech-software-company-in-vadodara/mycompany/" style="text-decoration: none; color: #333; padding:0 14px;"><img src="https://i.ibb.co/1MpdrG8/download-1.png" alt="LinkedIn" style="width: 15%;"></a>
<a href="https://www.youtube.com/@Veer_Agraval" style="text-decoration: none; color: #333; padding:0 14px;"><img src="https://i.ibb.co/b60S7TZ/download.png" alt="YouTube" style="width: 15%;"></a>
<a href="https://www.instagram.com/v_extech/?igshid=Zjc2ZTc4Nzk%3D" style="text-decoration: none; color: #333; padding:0 14px;"><img src="https://i.ibb.co/xYLHv49/download.jpg" alt="Instagram" style="width: 15%;"></a>
</div>
<p style="color: #666; font-size: 16px; margin-top: 20px;">Dhun Complex-301, Above Riya Bridal, near Amritsari Kulcha, opp. Pavan Park Society, Nizampura, Vadodara, Gujarat 390002</p>
</div>
</div>
',
attachments: [
{
filename: 'Receipt.pdf',
path: 'zakhna.pdf' // provide the path to your PDF file
}
]
};`
,


},






],

"coursename": "Express pdf mail",


},


{
"id": 17,


"html_title": "send-pdf-form-mail",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "frontend (MYForm.jsx)",





"bootstrap_title_desc": `import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const MyForm = () => {
return (
<Formik
initialValues={{
name: '',
mobileNumber: ''
}}
validationSchema={Yup.object({
name: Yup.string().required('Name is required'),
mobileNumber: Yup.string().required('Mobile number is required')
})}
onSubmit={(values) => {

fetch('http://localhost:3010/api/send-email', {
method: 'POST',
headers: {
'Content-Type': 'application/json'
},
body: JSON.stringify(values)
})
 
}}
>
<Form>
<label>
Name:
<Field type="text" name="name" />
<ErrorMessage name="name" component="div" />
</label>
<br />
<label>
Mobile Number:
<Field type="tel" name="mobileNumber" />
<ErrorMessage name="mobileNumber" component="div" />
</label>
<br />
<button type="submit">Submit</button>
</Form>
</Formik>
);
};

export default MyForm;
`
,


},


{
"bootstrap_title_heading": "Backend (index.js)",





"bootstrap_title_desc": `const express = require('express');
const { PDFDocument, rgb } = require('pdf-lib');
const nodemailer = require('nodemailer');
const fs = require('fs');
const cors = require('cors');

const app = express();
const port = 3010;

// Middleware to parse JSON bodies
app.use(express.json());
app.use(cors());

// Route to handle form submissions
app.post('/api/send-email', async (req, res) => {
const { name, mobileNumber } = req.body;

 try {
// Load existing PDF
const existingPdfPath = './slip.pdf';
const existingPdfBytes = fs.readFileSync(existingPdfPath);
const pdfDoc = await PDFDocument.load(existingPdfBytes);

// Modify existing PDF
const page = pdfDoc.getPage(0); // Assuming the text is on the first page
page.drawText('Name: name', { x: 50, y: 600, size: 12, color: rgb(0, 0, 0) });
page.drawText('Mobile Number: mobileNumber', { x: 50, y: 580, size: 12, color: rgb(0, 0, 0) });

const modifiedPdfBytes = await pdfDoc.save();

// Send email with modified PDF attachment
const transporter = nodemailer.createTransport({
service: 'gmail',
auth: {
 user: 'veer2238rajput@gmail.com',
 pass: 'ngpb hgqv hztj cuuc',
},
});

const mailOptions = {
from: 'veer2238rajput@gmail.com',
to: 'himanshu0409agraval@gmail.com',
subject: 'Welcome to VHX View',
html: '
 <p>Hello </p>
 <p>Thank you for registering with VHX View. We are excited to have you on board!</p>
 <p>Best regards,</p>
 <p>VHX View Team</p>
',
attachments: [
 {
filename: 'ModifiedReceipt.pdf',
content: modifiedPdfBytes,
 },
],
};

const info = await transporter.sendMail(mailOptions);
console.log('Email sent:', info.response);

res.send('Email sent successfully');
 } catch (error) {
console.error('Error sending email:', error);
res.status(500).send('Failed to send email');
 }
});

// Start the server
app.listen(port, () => {
 console.log('Server running at http://localhost:port');
});
`
,


},







],

"coursename": "Ej Form Pdf(day-11)",


},

{
"id": 17,


"html_title": "crud-opearation-database",


"bootstrap_head_desc": [

{
"bootstrap_title_heading": "models/Item.js",





"bootstrap_title_desc": `const mongoose = require('mongoose');

const itemSchema = new mongoose.Schema({
  name: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const Item = mongoose.model('Item', itemSchema);
module.exports = Item;
`
,


},

{
  "bootstrap_title_heading": "routes/itemRoutes.js",
  
  
  
  
  
  "bootstrap_title_desc": `const express = require('express');
const Item = require('../models/Item');
const app = express();

// Create
app.post('/items', async (req, res) => {
  const { name, description } = req.body;
  const newItem = new Item({ name, description });
  try {
    await newItem.save();
    res.status(201).json(newItem);
  } catch (error) {
    res.status(500).json({ message: 'Error creating item', error });
  }
});

// Read
app.get('/items', async (req, res) => {
  try {
    const items = await Item.find();
    res.status(200).json(items);
  } catch (error) {
    res.status(500).json({ message: 'Error fetching items', error });
  }
});

// Update
app.put('/items/:id', async (req, res) => {
  const { name, description } = req.body;
  try {
    const updatedItem = await Item.findByIdAndUpdate(
      req.params.id,
      { name, description },
      { new: true }
    );
    res.status(200).json(updatedItem);
  } catch (error) {
    res.status(500).json({ message: 'Error updating item', error });
  }
});

// Delete
item.delete('/items/:id', async (req, res) => {
  try {
    await Item.findByIdAndDelete(req.params.id);
    res.status(200).json({ message: 'Item deleted successfully' });
  } catch (error) {
    res.status(500).json({ message: 'Error deleting item', error });
  }
});

module.exports = app;
`
  ,
  
  
  },


  {
    "bootstrap_title_heading": "index.js",
    
    
    
    
    
    "bootstrap_title_desc": `const express = require('express');
const mongoose = require('mongoose');
const cors = require('cors');
const itemRoutes = require('./routes/itemRoutes');

const app = express();
app.use(cors());
app.use(express.json());

mongoose
.connect(
  "mongodb+srv://veer2238rajput:STrgrNlEXyfMZHBs@cluster0.3chkue4.mongodb.net/Contact?retryWrites=true&w=majority"
)
  .then(() => console.log('MongoDB connected'))
  .catch((err) => console.log('MongoDB connection error:', err));

app.use('/', itemRoutes);


app.listen(5000, () => {
  console.log('Server is connected');
});
`
    ,
    
    
    },


    {
      "bootstrap_title_heading": "src/components/ItemForm.js",
      
      
      
      
      
      "bootstrap_title_desc": `import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ItemForm = ({ fetchItems }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Name must be at least 3 characters')
        .required('Name is required'),
      description: Yup.string()
        .min(5, 'Description must be at least 5 characters')
        .required('Description is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      await axios.post('http://localhost:5000/api/items', values);
      resetForm(); // Reset form after successful submission
      fetchItems(); // Refresh the list after adding
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <div style={{ color: 'red' }}>{formik.errors.name}</div>
        ) : null}
      </div>
      <div>
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.description && formik.errors.description ? (
          <div style={{ color: 'red' }}>{formik.errors.description}</div>
        ) : null}
      </div>
      <button type="submit">Add Item</button>
    </form>
  );
};

export default ItemForm;
`
      ,
      
      
      },


      {
        "bootstrap_title_heading": "src/components/ItemList.js",
        
        
        
        
        
        "bootstrap_title_desc": `import React, { useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ItemList = ({ items, fetchItems }) => {
  const [editItemId, setEditItemId] = useState(null); // State to track which item is being edited

  const handleDelete = async (id) => {
   api link
    fetchItems(); // Refresh the list after deleting
  };

  const handleEdit = (id) => {
    setEditItemId(id); // Set the item ID that is being edited
  };

  const handleCancelEdit = () => {
    setEditItemId(null); // Reset editing state
  };

  return (
    <ul>
      {items.map((item) => (
        <li key={item._id}>
          {editItemId === item._id ? (
            <EditItemForm
              item={item}
              fetchItems={fetchItems}
              handleCancelEdit={handleCancelEdit}
            />
          ) : (
            <>
              <strong>{item.name}</strong> - {item.description}
              <button onClick={() => handleEdit(item._id)}>Edit</button>
              <button onClick={() => handleDelete(item._id)}>Delete</button>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

// Formik form for editing an item
const EditItemForm = ({ item, fetchItems, handleCancelEdit }) => {
  const formik = useFormik({
    initialValues: {
      name: item.name,
      description: item.description,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Name must be at least 3 characters')
        .required('Name is required'),
      description: Yup.string()
        .min(5, 'Description must be at least 5 characters')
        .required('Description is required'),
    }),
    onSubmit: async (values) => {
      api link
      fetchItems(); // Refresh the list after updating
      handleCancelEdit(); // Exit edit mode
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <input
          type="text"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <div style={{ color: 'red' }}>{formik.errors.name}</div>
        ) : null}
      </div>
      <div>
        <input
          type="text"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.description && formik.errors.description ? (
          <div style={{ color: 'red' }}>{formik.errors.description}</div>
        ) : null}
      </div>
      <button type="submit">Save</button>
      <button type="button" onClick={handleCancelEdit}>Cancel</button>
    </form>
  );
};

export default ItemList;
`
        ,
        
        
        },


        {
          "bootstrap_title_heading": "src/App.js",
          
          
          
          
          
          "bootstrap_title_desc": `import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ItemForm from './components/ItemForm';
import ItemList from './components/ItemList';

const App = () => {
  const [items, setItems] = useState([]);

  const fetchItems = async () => {
    const response = await axios.get('http://localhost:5000/api/items');
    setItems(response.data);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div>
      <h1>CRUD Operations</h1>
      <ItemForm fetchItems={fetchItems} />
      <ItemList items={items} fetchItems={fetchItems} />
    </div>
  );
};

export default App;
 `
          ,
          
          
          },










],

"coursename": "Ej Crud (day-12)",


},



{
  "id": 17,
  
  
  "html_title": "crud-cookies",
  
  
  "bootstrap_head_desc": [
  
  {
  "bootstrap_title_heading": "Yourcomponent.js",
  
  
  
  
  
  "bootstrap_title_desc": `// YourComponent.js
import React, { useContext } from "react";
import { ThemeContext } from "./context/MyContext";
 
const YourComponent = () => {
  const { theme, toggleTheme } = useContext(MyContext);
 
  return (
<div>
<h1>{theme === "light" ? "Light Mode" : "Dark Mode"}</h1>
<button onClick={toggleTheme}>
        Switch to {theme === "light" ? "Dark" : "Light"} Mode
</button>
</div>
  );
};
 
export default YourComponent;`
  ,
  
  
  },
  
  {
    "bootstrap_title_heading": "MyContenxtProvider.js",
    
    
    
    
    
    "bootstrap_title_desc": `import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
 
 
 
 const MyContenxtProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
 
  // Load theme from cookies
  useEffect(() => {
    const savedTheme = Cookies.get("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);
 
  // Save theme to cookies
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    Cookies.set("theme", newTheme, { expires: 7 }); // Saves for 7 days
  };
 
  return (
<MyContext.Provider value={{ theme, toggleTheme }}>
   {children}
</MyContext.Provider>
  );
};`
    ,
    
    
    },
  

  
  ],
  
  "coursename": "Ej Cookies (day-13)",
  
  
  },


  
{
  "id": 18,
  
  
  "html_title": "socket.io",
  
  
  "bootstrap_head_desc": [
  
  {
  "bootstrap_title_heading": "backend",
  
  
  
  
  
  "bootstrap_title_desc": `const express = require('express');
const http = require('http');
const { Server } = require('socket.io');
const cors = require('cors');
 
const app = express();
const server = http.createServer(app);
 
// Allow CORS for React app
app.use(cors());
 
// Initialize Socket.IO
const io = new Server(server, {
  cors: {
    origin: 'http://localhost:3000', // Your React app's URL
    methods: ['GET', 'POST']
  }
});
 
// Socket.IO event handling
io.on('connection', (socket) => {
    console.log('A user connected:', socket.id);
   
    // Example event: message from client
    socket.on('send_message', (data) => {
      console.log('Message received:', data);
      // Broadcast message to all clients
      io.emit('receive_message', data);
    });
   
    // Handle disconnect
    socket.on('disconnect', () => {
      console.log('User disconnected:', socket.id);
    });
  });
   
  const PORT = 3042;
  server.listen(PORT, () => {
    console.log('Server is connected');
  });
 
 
module.exports = app`
  ,
  
  
  },
  
  {
    "bootstrap_title_heading": "Frontend",
    
    
    
    
    
    "bootstrap_title_desc": `import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
 
 
const socket = io('http://localhost:3042');
 
const Socketio = () => {
   
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
   
    useEffect(() => {
      // Listen for messages from the server
      socket.on('receive_message', (data) => {
        setMessages((prevMessages) => [...prevMessages, data]);
      });
   
      // Cleanup the connection when the component unmounts
      return () => {
        socket.off('receive_message');
      };
    }, []);
   
    const sendMessage = () => {
      // Send message to the server
      socket.emit('send_message', message);
      setMessage(''); // Clear input field
    };
 
 
 
  return (
    <div >
    <h1>Socket.IO Chat</h1>
    <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
          />
    <button onClick={sendMessage}>Send</button>
     
          <div>
    <h2>Messages:</h2>
            {messages.map((msg, index) => (
    <p key={index}>{msg}</p>
            ))}
    </div>
    </div>
  )
}
 
export default Socketio`
    ,
    
    
    },
  

  
  ],
  
  "coursename": "Ej Socket.io (day-14)",
  
  
  },

  


]
export default ExpressJson