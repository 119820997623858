import React from 'react'
import './Module.scss'
import { MdVerifiedUser } from 'react-icons/md'
import { VscRecordSmall } from "react-icons/vsc";

const Module = ({outer,batch}) => {
  return (
    <div className='module-m'>
    <h2>{batch.includes('internship')?'Internship':'Course'} Modules</h2>

    <div className='module-list'>
{
    outer.container.map((i)=>{
        return(
            <div className='list'>
            <div className='up'>{i.name}</div>
        
            <li><MdVerifiedUser />{i.name}</li>
<div className='mk'>
            {
                i.topics.map((ii)=>{
                    return(
                        <p><VscRecordSmall /> {ii.list}</p>
                    )
                })
            }

            </div>
           
            
            </div>
        )
    })
   

}
    
    </div>
    </div>
  )
}

export default Module