import React, { useContext, useEffect, useState } from 'react';
import './Landing.scss';
import MyContext from '../../../Uttis/MyContext';

function HeroSection() {
 
    const [scrollY, setScrollY] = useState(0);
    const{setShowRight,handleselect} = useContext(MyContext)

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

    
    }, []);

    const translateY = Math.max(0, scrollY - 70); 

    return (

            <div className="hero-container">

          
                 <div className="contact-main"   style={{ transform: `translateY(-${translateY}px)` }}>
      {/* Outer larger pentagon */}
      <svg className="pentagon-border outer-pentagon" viewBox="0 0 100 100">
        <polygon points="50,1 98,38 79,95 21,95 2,38" stroke="blue" strokeWidth="0.4" fill="none" />
      </svg>


   <svg className="pentagon-border inner-pentagon" viewBox="0 0 100 100">
        <polygon points="50,1 98,38 79,95 21,95 2,38" stroke="blue" strokeWidth="0.4" fill="none" />
      </svg>

   
       <svg className="pentagon-border inner1-pentagon" viewBox="0 0 100 100">
        <polygon points="50,1 98,38 79,95 21,95 2,38" stroke="blue" strokeWidth="0.4" fill="none" />
      </svg>

<svg className="pentagon-border inner2-pentagon" viewBox="0 0 100 100">
        <polygon points="50,1 98,38 79,95 21,95 2,38" stroke="blue" strokeWidth="0.4" fill="none" />
      </svg>

      <svg className="pentagon-border inner3-pentagon" viewBox="0 0 100 100">
        <polygon points="50,1 98,38 79,95 21,95 2,38" stroke="blue" strokeWidth="0.4" fill="none" />
      </svg> 
    </div>
                <div className="hero-content">
                <h1 className="hero-title" style={{ transform: `translateY(-${translateY}px)`, textShadow: `-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black`, color: 'white' }}><span>Turn </span> your designs into <span>production</span>-ready frontend</h1>


                    <div className="hero-buttons">
                        <button  className="hero-button" onClick={()=>handleselect('training')}>Training</button>
                        <button  className="hero-button" onClick={()=>handleselect('internship')}>Internship</button>
                    </div>
                </div>
            </div>
 
    );
}

export default HeroSection;
