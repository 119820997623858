import React from 'react';
import certificate from '../../../../assets/Certificate/certificate.png';
import './Certificate.scss';
import { AiFillCheckCircle } from 'react-icons/ai';

const Certificate = ({ show }) => {
    return (
        < div className='certyo'>
        {show && <h2 className="certificate-title">Earn Recognized Certification</h2>}
            <div className={`certificate-box ${show ? 'certiok' : ''}`}>

          
            
                <img src={certificate} alt='Certificate Image' />
                <div className='certificate-content'>
                    <li><AiFillCheckCircle /> Skill Development</li>
                    <li><AiFillCheckCircle /> International Recognition</li>
                    <li><AiFillCheckCircle /> Valid for Jobs & Internships</li>
                    <li><AiFillCheckCircle /> Boosts Earning Potential</li>
                    <li><AiFillCheckCircle /> Issued by a Leading Software Company</li>
                </div>
            </div>
           
        </div>
    );
};

export default Certificate;
