import React, { useContext } from "react";
import { MdClose } from "react-icons/md";
import "./Search.scss";
import Product from "../Service/Course/Jason";
import { useState } from 'react';
import MyContext from "../../../Uttis/MyContext";


const Search = () => {

    const{showSearch,navigate,closeSearch} =useContext(MyContext)

    

    const [value, setValue] = useState('');

  


  



    return (

        <>

        {showSearch &&
        <div className="search-modal">
            <div className="form-field">
                <input
                    autoFocus
                    type="text"
                    placeholder="Search for..."
                    value={value} onChange={(e)=>setValue(e.target.value)}
                    
                />
                <MdClose
                    className="close-btn"
                    onClick={()=>setValue('') ||closeSearch()}
                />
            </div>
            <div className="search-result-content">

                <div className="search-results">

                    {Product
                        .filter((Item) => {
                            const searchTerm = value.toLowerCase();
                            const Coursename = Item.main.toLowerCase();
                            return (
                                searchTerm &&
                                Coursename.startsWith(searchTerm) 
                                

                            )
                        })



                        .map((Item) => (
                            <div className="search-result-item" onClick={() => {closeSearch() || navigate(`/course/${Item.Coursename}`) || setValue('')}}>


                                <div className="image-container">
                                    <img
                                        src={Item.student} alt=""
                                    />
                                </div>
                                <div className="prod-details">
                                    <span className="name">
                                        {Item.main}
                                    </span>

                                </div>
                            </div>

                        )

                        )}



                </div>
            </div>
        </div>
                    }

        </>
    );
};

export default Search;