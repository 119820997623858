import { FaHtml5,FaPhp, FaCss3Alt, FaBootstrap, FaReact, FaPython, FaLaravel, FaRProject, FaNodeJs, FaAndroid, FaAvianex, FaAppStore, FaFigma } from "react-icons/fa"
import { TbBrandJavascript, TbBrandNextjs, TbSql } from "react-icons/tb"
import { GiCyberEye} from "react-icons/gi"

import { SiJquery, SiMongodb, SiTailwindcss } from "react-icons/si"

import { SiExpress } from "react-icons/si";
import { Php } from "@mui/icons-material";


const PData = [

    {
        "id": 1,
        "course": "html",
        "icons": <FaHtml5 />,
    },



    {
        "id": 2,
        "course": "css",
        "icons": <FaCss3Alt />,
      


    },


    {
        "id": 3,
        "course": "css-advance",
        "icons": <FaCss3Alt />,
       


    },



    {
        "id": 4,
        "course": "javascript",
        "icons": <TbBrandJavascript />,
    

    },



    {
        "id": 5,
        "course": "excel",
        "icons": <SiJquery />,
       

       



    },



    {

        "id": 6,
        "course": "sql",
        "icons": <TbSql/>,
      
        


    },

    {
        "id": 11,
        "course": "python",
        "icons": <FaPython />,
        

    },



    {
        "id": 7,
        "course": "reactjs",
        "icons": <FaReact />,
       

    },

    
    {
        "id": 7,
        "course": "nextjs",
        "icons": <TbBrandNextjs />,
       

    },


    {
        "id": 11,
        "course": "react-native",
        "icons": <FaReact />,
        

    },

    {
        "id": 12,
        "course": "cyber-security",
        "icons": <GiCyberEye />,
     

    },

    {
        "id": 13,
        "course": "express-js",
        "icons": <SiExpress />,
     

    },

   
    {
        "id": 15,
        "course": "project",
        "icons": <FaAppStore />,
        

    },

    {
        "id": 16,
        "course": "tailwindcss",
        "icons": <SiTailwindcss/>,
        

    },

    {
        "id": 17,
        "course": "ui-ux",
        "icons": <FaFigma/>,
        

    },

    
    {
        "id": 17,
        "course": "php",
        "icons": <Php/>,
        

    },

   


  




]
export default PData