import React from 'react'
import './Banner.scss'

const Banner = ({data}) => {
  return (
    <div className='webbk'>
    
    <h1>{data}</h1>
    </div>
  )
}

export default Banner